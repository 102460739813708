<template>
  <div id="catPage">
    <!-- Tree Select -->
    <h3>Sélectionner la catégorie</h3>
    <a-tree-select
      v-model="value"
      style="width: 300px"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="categoriesTree"
      placeholder="Please select"
      tree-default-expand-all
      show-search
      @change="onSelectCat"
    >
    </a-tree-select>
    <div v-if="this.idCat">
      <br /><br />
      <h3>
        Selectionner les produits de la catégorie
        <a-button
          type="primary"
          icon="save"
          size="small"
          @click="save"
          v-if="isModified"
        />
      </h3>
      <div v-if="waitServer">
        <a-progress :percent="waitPercent" size="small" />
      </div>
      <a-transfer
        :data-source="productstoShow"
        show-search
        :list-style="{
          width: '500px',
          height: '800px',
        }"
        :target-keys="targetKeys"
        :render="(item) => `${item.reference}-${item.title}`"
        @change="handleChange"
      ></a-transfer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  data() {
    return {
      value: undefined,
      idCat: undefined,
      isModified: false,
      targetKeys: [],
      waitServer: false,
      waitMax: 0,
      waitActual: 0,
      leftTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
      rightTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
    };
  },
  components: {},
  computed: {
    ...mapState(["products", "categoriesList", "loading", "language"]),
    waitPercent: function() {
      var percent = (this.waitActual / this.waitMax) * 100;
      return percent.toFixed(0);
    },
    productstoShow() {
      var filter = _.filter(this.products, {
        active: "1",
      });
      var newArr = _.map(filter, function(element) {
        return _.extend({}, element, {
          key: element.id,
          title: element.name.language[0]["_"],
        });
      });
      console.log(newArr[0]);
      return newArr;
    },
    productsInCat: function() {
      var idCat = this.idCat;
      var filter = _.filter(this.productstoShow, function(item) {
        var sFilter = _.find(item.associations.categories.category, {
          id: idCat,
        });
        return typeof sFilter != "undefined" ? true : false;
      });
      var filterArray = [];
      filter.forEach(function(item) {
        filterArray.push(item.key);
      });
      return filterArray;
    },
    categoriesSimple: function() {
      var list = [];
      this.categoriesList.forEach((e) => {
        if (e.level_depth != "0") {
          list.push({
            id: parseInt(e.id),
            key: parseInt(e.id),
            parentId:
              parseInt(e.id_parent["_"]) < 2 ? 0 : parseInt(e.id_parent["_"]),
            title: e.id + '-' + e.name.language[this.language]["_"],
            value: parseInt(e.id) + "_" + e.name.language[this.language]["_"],
            level: parseInt(e.level_depth) - 1,
            children: null,
          });
        }
      });
      return list;
    },
    categoriesTree: function() {
      var list = this.categoriesSimple;
      var map = {},
        node,
        roots = [],
        i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== 0) {
          // if you have dangling branches check that map[node.parentId] exists
          if (typeof list[map[node.parentId]] != "undefined") {
            list[map[node.parentId]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
  },
  methods: {
    onSelectCat(value) {
      var idCat = value.split("_");
      this.idCat = idCat[0];
      this.targetKeys = this.productsInCat;
    },
    handleChange(targetKeys) {
      this.targetKeys = targetKeys;
      this.isModified = true;
    },
    save() {
      var sKeys = this.targetKeys,
        idCat = this.idCat;
      this.loading = true;
      this.$message.loading({
        content: "Sauvegarde ...",
        key: "productCatEdit",
      });
      this.waitServer = true;
      var newProductInCat = _.filter(this.productstoShow, function(item) {
        return _.includes(sKeys, item.key);
      });
      var productsInCat = _.filter(this.productstoShow, function(item) {
        var sFilter = _.find(item.associations.categories.category, {
          id: idCat,
        });
        return typeof sFilter != "undefined" ? true : false;
      });
      var productToRemove = _.differenceBy(
        productsInCat,
        newProductInCat,
        "key"
      );
      var productToAdd = _.differenceBy(newProductInCat, productsInCat, "key");
      this.waitMax = productToAdd.length + productToRemove.length;
      this.$store
        .dispatch("editProductsCat", {
          idCat: idCat,
          productsToAdd: productToAdd,
          productsToRemove: productToRemove,
        })
        .then(([id, socket]) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
          this.sockets.subscribe(socket, (data) => {
            this.waitActual = data.num;
            if (data.result)
              this.$store.commit("CHANGE_Product", {
                id: data.id,
                data: data.data,
              });
            if (this.waitActual == this.waitMax) {
              this.sockets.unsubscribe(socket);
              this.waitServer = false;
              this.loading = false;
            }
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
  },
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
