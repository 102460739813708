<template>
  <div class="filterSearch">
    <span v-if="searchText && searchedColumn === column.dataIndex">
      <template
        v-for="(fragment, i) in text
          .toString()
          .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
      >
        <mark
          v-if="fragment.toLowerCase() === searchText.toLowerCase()"
          :key="i"
          class="highlight"
          >{{ fragment }}</mark
        >
        <template v-else>{{ fragment }}</template>
      </template>
    </span>
    <template v-else>
      {{ text }}
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "seachTable",
  props: [
    "text",
    "record",
    "index",
    "column",
  ],
  computed: {
    ...mapState(["language"]),
  },
  methods: {
    
  },
};
</script>
