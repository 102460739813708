<template>
  <div class="product_description">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="notedit" />
      <a-button
        type="primary"
        icon="save"
        size="small"
        @click="save(record.id)"
        v-if="edited"
      />
      <div
        class="textEdition"
        v-for="langue in languageDetail"
        :key="langue.key"
      >
        <p>
          {{ langue.name }}
          <a-button
            icon="flag"
            v-if="langue.key != 0"
            size="small"
            @click="
              translate(
                $event,
                record.meta_title.language[0]['_'],
                langue.code,
                langue.key
              )
            "
          />
        </p>
        <a-textarea
          :placeholder="langue.name + ' meta_title'"
          :rows="4"
          v-model="record.meta_title.language[langue.key]['_']"
          @change="handleChange($event, langue.key)"
        >
        </a-textarea>
      </div>
    </div>
    <div v-else>
      {{ record.meta_title.language[language]["_"] }}
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "meta_title",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      edited: false,
      initialValues: [],
      editableValues: [],
    };
  },
  computed: {
    ...mapState([
      "user",
      "language",
      "languageDetail",
      "featuresList",
      "userToken",
    ]),
  },
  components: {},
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    save(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Title (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "editContent",
          subAction: "meta_title",
          id: idProduct,
          content: this.record.meta_title.language,
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    translate(value, sequence, lang, key) {
      //console.log(`selected ${value} for ${typeFeatures} and product ${idProduct}`);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/translate",
          {
            id: this.user.id,
            sequence: sequence,
            lang: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((items) => {
          this.record.meta_title.language[key]["_"] = items;
          this.edited = true;
        });
    },
    handleChange(e, key) {
      this.record.meta_title.language[key]["_"] = e.target.value;
      this.edited = true;
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}</style
>>
