<template>
  <div id="login">
    <a-row type="flex" justify="space-around" align="middle">
      <a-col :span="8">
        <a-card :loading="waitLogin" title="Connectez vous">
          <a-form-model
            layout="inline"
            :model="formInline"
            @submit="handleSubmit"
            @submit.native.prevent
          >
            <a-form-model-item>
              <a-input v-model="formInline.email" placeholder="Email">
                <a-icon
                  slot="prefix"
                  type="mail"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="formInline.email === ''"
                >Se connecter</a-button
              >
            </a-form-model-item>
          </a-form-model>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "home",
  data() {
    return {
      waitLogin: true,
      isLoggedIn: false,
      email: "",
      formInline: {
        email: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      //console.log(this.formInline);
      const redirectURI = `${window.location.origin}/admin/logged`;
      const email = this.formInline.email;
      if (email) {
        this.waitLogin = true;
        var auth = await window.m.auth.loginWithMagicLink({ email, redirectURI });
        if(auth)
          this.checkLogin();

      }
    },
    async checkLogin() {
      this.isLoggedIn = await window.m.user.isLoggedIn();
    if (this.isLoggedIn) {
      var getData = await window.m.user.getMetadata();
      var getToken = await window.m.user.getIdToken();
      this.user = getData.email;
      axios
        .post(process.env.VUE_APP_APIURL + "/agent", {
          email: this.user,
          token: getToken,
        })
        .then((response) => {
          if (response.data.login) {
            this.$router.push(
              { name: "check", params: { user: response.data.reviewer, welcomeMSG : response.data.welcomeMSG}}
            );
          } else {
            alert("non autorise");
            this.waitLogin = false;
            this.isLoggedIn = false;
            window.m.user.logout();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.buttonNotBLocked = true;
        });
    } else {
      this.waitLogin = false;
      this.isLoggedIn = false;
    }
    }
  },
  async mounted() {
    this.checkLogin();
  },
};
</script>

<style lang="less" scoped>
#login {
  height: 100vh;
  .ant-row-flex {
    height: 100%;
  }
}
</style>