<template>
  <div id="productsMassEdit">
    <a-col :span="20" class="steps"> </a-col>
    <a-col :span="12">
      <div
        v-if="tickets.length == 0"
        id="drop"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
      >
        Drop XLS / XLSX Here
        <br />
        ID MUST BE ON FIRST COL
      </div>
      <div v-else>
        <h2>Instructions</h2>
        <p v-if="!mapperReady">Selectionner le mapping</p>
        <p v-if="!selectReady">Selectionner les produits à mettre à jour</p>
        <a-button
          v-if="selectReady && mapperReady && !waitServer"
          type="danger"
          @click="massEdit"
        >
          Mettre à jour !
        </a-button>
        <a-progress v-if="waitServer" type="circle" :percent="waitPercent" />
      </div>
    </a-col>
    <a-col :span="12">
      <a-descriptions title="Mapper" :size="small" bordered>
        <a-descriptions-item
          :span="4"
          :label="col.title"
          v-for="col in columns"
          :key="col.id"
        >
          <div v-if="col.id == 0">
            <a-select
              show-search
              placeholder="Select a field"
              option-filter-prop="children"
              style="width: 200px"
              :filter-option="filterOption"
              @change="handleChange"
            >
              <a-select-option
                v-for="opt in mappValue"
                :key="opt"
                :index="col.id"
                :value="opt.title"
              >
                <span>{{ opt.title }}</span>
              </a-select-option>
            </a-select>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
    <a-col :span="24"> </a-col>
    <a-col :span="24" class="tableData">
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="tickets"
        :rowKey="rowKey"
        :pagination="false"
        :rowClassName="tableRowClassName"
      >
      </a-table>
    </a-col>
  </div>
</template>

<script>
function get_header_row(sheet) {
  var headers = [],
    range = window.XLSX.utils.decode_range(sheet["!ref"]);
  var C,
    R = range.s.r; /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) {
    /* walk every column in the range */
    var cell =
      sheet[
        window.XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */
    var hdr = "UNKNOWN " + C; // <-- replace with your desired default
    if (cell && cell.t) hdr = window.XLSX.utils.format_cell(cell);
    headers.push(hdr);
  }
  return headers;
}
function fixdata(data) {
  var o = "",
    l = 0,
    w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "productsMassEdit",
  data() {
    return {
      tickets: [],
      headers: ["Test header"],
      mapper: [],
      mapperReady: null,
      selectReady: null,
      selectedValue: null,
      waitServer: null,
      waitMax: null,
      waitActual: 0,
      indexMappValue: ["id", "reference"],
    };
  },
  components: {},
  computed: {
    ...mapState(["products", "soldesRules", "languageDetail", "loading"]),
    mappValue: function() {
      var rules = [];
      _.forEach(this.soldesRules, function(value) {
        rules.push({
          title: value.name,
          id: value.id,
          trad: false,
          underscore: false,
        });
      });
      return rules;
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedValue = selectedRows;
          if (selectedRows.length > 0) this.selectReady = true;
          else {
            this.selectReady = false;
          }
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled:
              typeof _.find(this.products, {
                id: record[this.columns[0]["dataIndex"]],
              }) == "undefined" &&
              typeof _.find(this.products, {
                reference: record[this.columns[0]["dataIndex"]],
              }) == "undefined",
            name: record.name,
          },
        }),
      };
    },
    columns: function() {
      var keys = _.keys(this.tickets[0]);
      var columns = [],
        x = 0;
      keys.forEach(function(col) {
        if (x == 0)
          columns.push({
            id: x,
            dataIndex: col,
            title: () => {
              return "ID";
            },
            selectedMapper: {
              value: null,
              traductable: false,
              lang: null,
              underscore: null,
            },
          });
        else
          columns.push({
            id: x,
            dataIndex: col,
            title: col,
            selectedMapper: {
              value: null,
              traductable: false,
              lang: null,
              underscore: null,
            },
          });
        x++;
      });
      return columns;
    },
    // mappValue: function() {
    //   var keys = _.keys(this.products[0]);
    //   console.log(keys);
    //   return keys;
    // },
    rowKey: function() {
      var keys = _.keys(this.tickets[0]);
      return keys[0];
    },
    waitPercent: function() {
      var percent = (this.waitActual / this.waitMax) * 100;
      return percent.toFixed(0);
    },
  },
  mounted() {
    let xlsxScript = document.createElement("script");
    xlsxScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.8.0/xlsx.full.min.js"
    );
    document.head.appendChild(xlsxScript);
  },
  methods: {
    tableRowClassName(row) {
      if (
        typeof _.find(this.products, { id: row[this.columns[0]] }) ==
        "undefined"
      ) {
        return "inactive-row";
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files,
        thos,
        i,
        f;
      thos = this;
      for (i = 0, f = files[i]; i != files.length; ++i) {
        var reader = new FileReader();
        reader.onload = function(e) {
          var results,
            data = e.target.result,
            fixedData = fixdata(data),
            workbook = window.XLSX.read(btoa(fixedData), { type: "base64" }),
            firstSheetName = workbook.SheetNames[0],
            worksheet = workbook.Sheets[firstSheetName];
          thos.headers = get_header_row(worksheet);
          results = window.XLSX.utils.sheet_to_json(worksheet);
          // var keys = _.keys(results[0]);
          // var columns = {}
          // keys.forEach(function(col) {
          //   columns[col] =
          // });
          // results.unshift(columns);
          thos.tickets = results;
        };
        reader.readAsArrayBuffer(f);
      }
    },
    handleChange(e, o) {
      this.mapper[o.data.attrs.index] = e;
      this.columns[o.data.attrs.index].selectedMapper.value = e;
      this.columns[o.data.attrs.index].id = _.filter(this.mappValue, {
        title: e,
      })[0]["id"];

      this.mapperReady = true;
    },
    handleChangeLang(e, o) {
      this.columns[o.data.attrs.index].selectedMapper.lang = e;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    massEdit() {
      var key = "massEdit";
      this.$message.loading({
        content: "Modification En Masse ...",
        key: key,
      });
      this.waitServer = true;
      console.log(this.selectedValue);
      console.log(this.columns);
      this.$store
        .dispatch("addSoldes", {
          action: "massEdit",
          products: this.selectedValue,
          columns: this.columns,
        })
        .then(([id, socket, maxValue]) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
          this.waitMax = maxValue;
          this.sockets.subscribe(socket, (data) => {
            this.waitActual = data.num;
            if(data.result)
              this.$store.commit("ADD_Soldes", { data: data.data});
            if(this.waitActual == this.waitMax)
            this.sockets.unsubscribe(socket);
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
  },
};
</script>

<style lang="scss">
#productsMassEdit {
  width: 90%;

  .steps {
    margin: 25px;
  }
  .tableData {
    margin: 25px 0;
  }

  .inactive-row {
    background-color: rgba(215, 44, 44, 0.2);
  }
}
#drop {
  width: 100%;
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  font: 20pt bold, "Vollkorn";
  color: #bbb;
}
</style>
