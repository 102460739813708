<template>
  <a-layout-sider
    breakpoint="sm"
    collapsed-width="0"
    style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
  >
    <div class="logo">
      <img src="@/assets/logo.jpg" />
    </div>

    <a-menu
      :default-selected-keys="['1']"
      :default-open-keys="['sub2']"
      mode="inline"
      theme="dark"
    >
      <a-menu-item key="1" @click="showDasboard">
        <span>Dashboard</span>
      </a-menu-item>
      <a-sub-menu key="sub0">
        <span slot="title">
          <a-icon
            v-if="waStatus.api == 'unknown'"
            slot="icon"
            type="sync"
            spin
          />
          <a-icon
            v-else-if="waStatus.api == true && waStatus.server == true"
            slot="icon"
            type="check-circle"
            :style="{ color: '#52c41a' }"
          />
          <a-icon v-else slot="icon" type="exclamation-circle" />
          <a-popover title="Info API">
            <template slot="content">
              <p>
                Server : {{ waStatus.server }} / {{ waStatus.lastok.server }}
              </p>
              <p>API : {{ waStatus.api }} / {{ waStatus.lastok.api }}</p>
              <p>Dernier info reçu : {{ waStatus.lastcheck }}</p>
            </template>
            <span>API Whatsapp</span>
          </a-popover>
        </span>
        <a-menu-item key="05" @click="logsWA">
          Voir les logs
        </a-menu-item>

        <a-menu-item key="08" @click="gestionWAArtisans">
          Gestion des artisans
        </a-menu-item>
        
        <a-menu-item key="07">
          <a href="https://sav-web.host.tribaliste.com/" target="_blank" rel="noopener noreferrer">
          <a-icon type="link" /> Interface de Chat
          </a>
        </a-menu-item>
        
        <a-menu-item key="06" @click="killWA">
          <a-icon type="warning" style="color:#FFCC00;"/>
          Relancer l'API
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub1">
        <span slot="title">
          <a-avatar class="anticon" :size="14" :src="$auth.user.picture" />
          <span>Profil</span>
        </span>
        <a-menu-item key="6" @click="$auth.logout">
          Se deconnecter
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="sub3">
        <span slot="title">
          <a-icon v-if="loadings.products" slot="icon" type="loading" />
          <a-icon v-else slot="icon" type="shopping-cart" />
          <span @click="showProducts">Produits</span>
        </span>
        <a-menu-item key="3" @click="showProducts">
          <span>voir tous ({{ products.length }})</span>
        </a-menu-item>
        <a-menu-item key="7" @click="showProductCategories">
          <span>categories</span>
        </a-menu-item>
        <a-menu-item key="8" @click="showProductFeatures">
          <span>features</span>
        </a-menu-item>
        <a-menu-item key="4" @click="editProducts">
          <span>edition en masse</span>
        </a-menu-item>
        <a-menu-item key="5" @click="tradProducts">
          <span>traductions</span>
        </a-menu-item>
        <a-menu-item key="6" @click="refnatProducts">
          <span>RefNat</span>
        </a-menu-item>
        <a-menu-item key="9" @click="addProducts">
          <span>ajout en masse</span>
        </a-menu-item>
        <a-menu-item key="10" @click="soldesProducts">
          <span>Gestion Soldes</span>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub4">
        <span slot="title">
          <a-icon v-if="loadings.orders" slot="icon" type="loading" />
          <a-icon v-else slot="icon" type="shop" />

          <span>Commandes</span>
        </span>
        <a-menu-item key="43" @click="showOrders">
          <span>voir tous ({{ orders.length }})</span>
        </a-menu-item>
        <a-menu-item key="44" @click="showRenta">
          <span>Rentabilité</span>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub6">
        <span slot="title">
          <a-icon v-if="loadings.orders" slot="icon" type="loading" />
          <a-icon v-else slot="icon" type="tags" />

          <span>Artisans</span>
        </span>

        <a-menu-item key="64" @click="showPos">
          <span>POs artisans ({{ pos.length }})</span>
        </a-menu-item>
        <a-menu-item key="61" @click="showArtisansStocks">
          <span>Suivi stock</span>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub5">
        <span slot="title">
          <a-icon v-if="loadings.features" slot="icon" type="loading" />
          <a-icon v-else slot="icon" type="star" />

          <span>Features</span>
        </span>
        <a-menu-item key="53" @click="delFeatures">
          <span>Suppression</span>
        </a-menu-item>
        <a-menu-item key="54" @click="tradFeatures">
          <span>Traduction</span>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <div class="flowLoad" align="bottom">
      <a-popconfirm
        title="Le serveur envoi une mise à jour, accepter ?"
        :visible="getUpdateFromServer"
        ok-text="Oui"
        cancel-text="Non"
        @visibleChange="handleVisibleChange"
        @confirm="confirmReceveiveUpdate"
        @cancel="cancel"
      >
      </a-popconfirm>
      <a-steps size="small" direction="vertical">
        <a-step title="Flush Cache">
          <a-icon
            v-if="loadings.orders || loadings.products"
            @click="flush"
            slot="icon"
            type="loading"
          />
          <a-icon v-else slot="icon" @click="flush" type="reload" />
        </a-step>
      </a-steps>
    </div>
    {{ visible }}
    <a-modal v-model="visible" title="Logs whatsapp" on-ok="closeWaPopup">
      <template slot="footer">
        <a-button key="submit" type="primary" @click="closeWaPopup">
          Fermer
        </a-button>
      </template>
      <a-textarea :auto-size="{ minRows: 3, maxRows: 25 }" :value="logs" />
    </a-modal>
  </a-layout-sider>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import axios from "axios";

export default {
  name: "Menu",
  data() {
    return {
      visible: false,
      logs: "",
    };
  },
  computed: {
    ...mapState([
      "loading",
      "loadings",
      "user",
      "products",
      "orders",
      "pos",
      "updateFromServer",
      "userToken",
      "waStatus",
    ]),
    getUpdateFromServer() {
      var filter = _.findKey(this.updateFromServer, { active: true });
      if (typeof filter == "string") {
        if (
          this.$moment().isAfter(this.updateFromServer.stop) ||
          this.updateFromServer.stop == 0
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },
  methods: {
    showCV(item) {
      this.$router.push("/dashboard/cv/" + item.key);
    },
    showDasboard() {
      this.$router.push("/dashboard");
    },
    showProducts() {
      this.$router.push("/products");
    },
    showProductCategories() {
      this.$router.push("/products/categories");
    },
    showProductFeatures() {
      this.$router.push("/products/features");
    },
    editProducts() {
      this.$router.push("/products/massEdit");
    },
    addProducts() {
      this.$router.push("/products/massUpload");
    },
    tradProducts() {
      this.$router.push("/products/traduction");
    },
    soldesProducts() {
      this.$router.push("/products/soldes");
    },
    refnatProducts() {
      this.$router.push("/products/refnat");
    },
    delFeatures() {
      this.$router.push("/features/delete");
    },
    tradFeatures() {
      this.$router.push("/features/traduction");
    },
    showOrders() {
      this.$router.push("/orders");
    },
    showRenta() {
      this.$router.push("/orders/renta");
    },
    showPos() {
      this.$router.push("/artisans/pos");
    },
    showArtisansStocks() {
      this.$router.push("/artisans/stocks");
    },
    gestionWAArtisans() {
      this.$router.push("/artisans/whatsapp");
    },
    flush() {
      this.$store.dispatch("flushCache");
    },
    confirmReceveiveUpdate() {
      var from = _.findKey(this.updateFromServer, { active: true });
      this.$store.dispatch("acceptUpdateFromServer", from);
    },
    cancel() {
      var from = _.findKey(this.updateFromServer, { active: true });
      this.$store.dispatch("receiveUpdateFromServerInactive", {
        from: from,
        stop: this.$moment()
          .add(1, "minutes")
          .format(),
      });
    },
    killWA() {
      var thos = this;
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/restart",
          {},
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          thos.visible = true;
          thos.sockets.subscribe("WAlogs", (data) => {
            thos.logs += data.log + "\n";
          });
        });
    },
    logsWA() {
      var thos = this;
      thos.logs = "";
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/getLogs",
          {},
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          thos.visible = true;
          thos.sockets.subscribe("WAlogs", (data) => {
            thos.logs += data.log + "\n";
          });
        });
    },
    closeWaPopup() {
      var thos = this;
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/stopLogs",
          {},
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          thos.visible = false;
          thos.sockets.unsubscribe("WAlogs");
          thos.logs = "";
        });
    },
  },
};
</script>

<style lang="scss">
.logo {
  margin: 16px;
  background: #fff;
  img {
    min-height: 100%;
    max-width: 100%;
  }
}
.ant-menu {
  position: relative;
  .ant-avatar {
    margin-right: 10px;
  }
}

.flowLoad {
  position: absolute;
  bottom: 15px;
  margin-left: 15px;
  .ant-steps .ant-steps-item-description,
  .ant-steps .ant-steps-item-title,
  .ant-steps-icon {
    color: #fff !important;
  }
}
</style>
