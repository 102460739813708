<template>
  <div class="container">
    <div class="row align-items-center cv-result" v-if="!loading">
      <!-- <a-row class="line" type="flex" :gutter="16">
        <cvResult />
      </a-row> -->
      <a-row class="line" type="flex" :gutter="16">
        <productsTrad/>
      </a-row>
    </div>
    <div class="loading" v-else="">
      <a-row class="content" type="flex" justify="space-around" align="middle">
        <a-col :span="4">
          <a-spin tip="Chargement..." size="large"></a-spin>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import productsTrad from "../../components/products/productsTrad";
export default {
  name: "Products_missing_trad",
  components: { productsTrad },
  computed: {
    ...mapState(["products", "loading", "loadings"]),
  },
  created() {
    if(!this.products)
      this.$store.dispatch("loadUser", this.$auth.user.sub);
  },
};
</script>

