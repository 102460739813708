<template>
  <div id="traductionPage">
    <a-tabs default-active-key="2">
      <a-tab-pane
        key="2"
        :tab="'Description (' + needData_description.length + ')'"
        :disabled="needData_description.length == 0"
      >
        <p>Produit actifs et en stock</p>
        <tableRefnat
          :keyName="'description_short'"
          :type="'Description'"
          :data="needData_description"
        />
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="'Meta Title(' + needData_meta_title.length + ')'"
        :disabled="needData_meta_title.length == 0"
      >
        <p>Produit actifs et en stock</p>
        <tableRefnat
          :keyName="'meta_title'"
          :type="'Meta Title'"
          :data="needData_meta_title"
        />
      </a-tab-pane>
      <a-tab-pane
        key="4"
        :tab="'Meta Description(' + needData_meta_description.length + ')'"
        :disabled="needData_meta_description.length == 0"
      >
        <p>Produit actifs et en stock</p>
        <tableRefnat
          :keyName="'meta_description'"
          :type="'Meta Description'"
          :data="needData_meta_description"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import tableRefnat from "./refnat/table";
import { mapState } from "vuex";

export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        title: true,
        description: true,
        meta_title: true,
        meta_description: true,
      },
    };
  },
  components: {
    tableRefnat,
  },
  computed: {
    ...mapState(["products", "language", "loading"]),
    needData_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.description_short.language[0]["_"] == "undefined") {
            needTrad.push(product);
          } else if (product.description_short.language[0]["_"] == "") needTrad.push(product);
      });
      return needTrad;
    },
    needData_meta_title() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.meta_title.language[0]["_"] == "undefined")
            needTrad.push(product)
          else if (product.meta_title.language[0]["_"] == "") needTrad.push(product);
      });
      return needTrad;
    },
    needData_meta_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.meta_description.language[0]["_"] == "undefined")
            needTrad.push(product)
          else if (product.meta_description.language[0]["_"] == "")
              needTrad.push(product);
      });
      return needTrad;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
