<template>
  <a-descriptions title="Product Detail" :size="middle" bordered>
    <a-descriptions-item :span="4" label="Name">
      <productName :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Prix artisan">
      <productWholesalePrice :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Dimensions">
      <productDimensions :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Description">
      <productDescription :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Link Rewrite">
      <productLink :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4"
      label="Categories"
    >
      <categoriesDetail :record="record" />
    </a-descriptions-item>
    <a-descriptions-item label="Features" :span="4">
      <featuresDetail :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Meta - title">
      <productMetaTitle :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Meta - description">
      <productMetaDescription :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Redirection">
      <redirectionDetail :record="record" />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Actions supplémentaires">
      <productActions :record="record" />
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapState } from "vuex";
import productDescription from "./details/product_description";
import productName from "./details/product_name";
import productWholesalePrice from "./details/product_wholesale_price";
import productActions from "./details/product_actions";
import productDimensions from "./details/product_dimensions";
import productLink from "./details/product_link";
import productMetaTitle from "./details/product_meta_title";
import productMetaDescription from "./details/product_meta_description";
import featuresDetail from "./details/features";
import categoriesDetail from "./details/categories";
import redirectionDetail from "./details/redirection_detail";


export default {
  name: "productDetails",
  props: ["record"],
  components: {
    featuresDetail,
    categoriesDetail,
    productDescription,
    productName,
    productWholesalePrice,
    productDimensions,
    productLink,
    productMetaTitle,
    productMetaDescription,
    redirectionDetail,
    productActions
  },
  computed: {
    ...mapState(["language", "featuresList"]),
  },
};
</script>

