<template>
  <div class="product_description">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="notedit" />
      <a-button
        type="primary"
        icon="save"
        size="small"
        @click="save(record.id)"
        v-if="edited"
      />
      <div
        class="textEdition"
        v-for="langue in languageDetail"
        :key="langue.key"
      >
        <p>
          {{ langue.name }}
          <a-button
            icon="flag"
            v-if="langue.key != 0"
            size="small"
            @click="
              translate(
                $event,
                record.name.language[0]['_'],
                langue.code,
                langue.key
              )
            "
          />
        </p>
        <a-textarea
          :placeholder="langue.name + ' Name'"
          :rows="4"
          v-model="record.link_rewrite.language[langue.key]['_']"
          @change="handleChange($event, langue.key)"
        >
        </a-textarea>
      </div>
    </div>
    <div v-else>
      {{ record.link_rewrite.language[language]["_"] }}
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "product",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      edited: false,
      initialValues: [],
      editableValues: [],
    };
  },
  computed: {
    ...mapState([
      "user",
      "language",
      "languageDetail",
      "featuresList",
      "userToken",
    ]),
  },
  components: {},
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    save(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Link (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "editContent",
          subAction: "link_rewrite",
          id: idProduct,
          content: this.record.link_rewrite.language,
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    str2url(aStr) {
      // converti le texte en minuscule
      var s = aStr.toLowerCase();
      // remplace les a accentué
      s = s.replace(/[àâäáã]/g, "a");
      // remplace les e accentué
      s = s.replace(/[èêëé]/g, "e");
      // remplace les i accentué
      s = s.replace(/[ìîïí]/g, "i");
      // remplace les u accentué
      s = s.replace(/[ùûüú]/g, "u");
      // remplace les o accentué
      s = s.replace(/[òôöó]/g, "o");
      // remplace le c cédille
      s = s.replace(/[ç]/g, "c");
      // remplace le ene tilde espagnol
      s = s.replace(/[ñ]/g, "n");
      // remplace tous les caractères qui ne sont pas alphanumérique en tiret
      s = s.replace(/\W/g, "-");
      // remplace les double tirets en tiret unique
      s = s.replace(/-+/g, "-");
      // renvoi le texte modifié
      return s;
    },
    translate(value, sequence, lang, key) {
      //console.log(`selected ${value} for ${typeFeatures} and product ${idProduct}`);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/translate",
          {
            id: this.user.id,
            sequence: sequence,
            lang: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((items) => {
          this.record.link_rewrite.language[key]["_"] = this.str2url(items.trim());
          this.edited = true;
        });
    },
    handleChange(e, key) {
      this.record.link_rewrite.language[key]["_"] = e.target.value;
      this.edited = true;
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}</style
>>
