<template>
  <div class="features">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <p>Si la features n'est pas disponible, merci de la rajouter</p>
      <a-select
        :default-value="features_value(specFeatures)"
        style="width: 120px"
        @change="handleChange($event, specFeatures, record.id)"
        size="small"
        v-for="specFeatures in featuresID" 
        :key="specFeatures"
      >
        <my-icon slot="suffixIcon" v-if="specFeatures == '10'" type="icon-city" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '11'" type="icon-region" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '14'" type="icon-thread-" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '15'" type="icon-leather" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '16'" type="icon-paint-fill" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '17'" type="icon-hammer" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '18'" type="icon-design" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '24'" type="icon-colors" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '25'" type="icon-design" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '26'" type="icon-colors" />
        <my-icon slot="suffixIcon" v-if="specFeatures == '27'" type="icon-colors" />
        <a-select-option
          v-for="feat in featuresListFiltered(specFeatures)"
          :key="feat.id"
        >
          {{feat.value.language[language]['_']}}
        </a-select-option>
      </a-select>
      <a-button icon="close" size="small" @click="notedit" />
    </div>
    <div v-else>
      <a-tag v-for="feat in features" :key="feat">
        <my-icon v-if="feat.id == '10'" type="icon-city" />
        <my-icon v-if="feat.id == '11'" type="icon-region" />
        <my-icon v-if="feat.id == '14'" type="icon-thread-" />
        <my-icon v-if="feat.id == '15'" type="icon-leather" />
        <my-icon v-if="feat.id == '16'" type="icon-paint-fill" />
        <my-icon v-if="feat.id == '17'" type="icon-hammer" />
        <my-icon v-if="feat.id == '18'" type="icon-design" />
        <my-icon v-if="feat.id == '24'" type="icon-colors" />
        <my-icon v-if="feat.id == '25'" type="icon-design" />
        <my-icon v-if="feat.id == '26'" type="icon-colors" />
        <my-icon v-if="feat.id == '27'" type="icon-colors" />
        <span v-if="feat.value"> {{ feat.value.language[language]["_"] }}</span>
      </a-tag>
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>

<script>
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2226984_zckulf83q9h.js",
});
const isPrime = function(feature) {
  var okId = ["10", "11", "14", "15", "16", "17", "18", "24", "25", "26", "27"];
  return okId.includes(feature.id);
};
import { Icon } from "ant-design-vue";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "features",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      featuresID : ["10", "11", "14", "15", "16", "17", "18", "24", "25", "26", "27"]
    };
  },
  computed: {
    ...mapState(["language", "featuresList"]),
    features: function() {
      return this.record.associations.product_features.product_feature.filter(
        isPrime
      );
    },
  },
  components: {
    "my-icon": MyIcon,
  },
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    features_value(id) {
      var search = _.filter(
        this.record.associations.product_features.product_feature,
        {
          id: id,
        }
      );
      if (search.length > 0)
        return search[0]["id_feature_value"]["_"];
      else return "";
    },
    featuresListFiltered(id) {
      var search = _.filter(this.featuresList, {
        custom : "0",
        id_feature: {
          _: id,
        },
      });
      if (typeof search != "undefined") return search;
      else return "";
    },
    handleChange(value, typeFeatures, idProduct) {
      console.log(`selected ${value} for ${typeFeatures} and product ${idProduct}`);
      var key = idProduct
      this.$message.loading({ content: "editProductFeatures (" + idProduct + ")...", key : key});
      this.$store
        .dispatch("editProduct", {action : "editFeatures", id : idProduct, idFeature : typeFeatures, value : value})
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key : id });
        })
        .catch(([error, id]) => {
          this.$message.error({content: "Echet de l'édition " + error, key : id});
        });
    },
  },
};
</script>

<style lang="scss">
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
