<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input
        :value="value * 1"
        @change="handleChange"
        @pressEnter="check"
      /><a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value * 1 || " " }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "priceArtisan",
  props: ["text", "data"],
  computed: {
    ...mapState(["language", "loadings"]),
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.editPrice(this.data.id, this.value)
    },
    edit() {
      this.editable = true;
    },
    editPrice(id, value) {
      this.$message.loading({ content: "Edition prix (" + id + ")...", key: id });
      this.$store
        .dispatch("editProduct", { id: id, action: "wholesale_price",  value : value})
        .then((id) => {
          this.$message.success({ content: "Modification réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la modification " + error,
            key: id,
          });
        });
    }
  },
};
</script>
