<template>
  <div id="catPage">
    <!-- Tree Select -->
    <div v-if="waitServer">
        <a-progress :percent="waitPercent" size="small" />
      </div>
    <h3>Sélectionner la Feature</h3>
    <a-cascader
      :options="featuresListTree"
      placeholder="Select"
      @change="onSelectCat"
    />

    <div v-if="this.idCat">
      <h3>Produits avec cette Feature</h3>  
      <p>{{ productsInCat }}</p>

      <div v-if="productsInCat.length > 0">
        <h3>Deplacer vers ?</h3>
        <a-cascader
          :options="featuresListTreeFiltered"
          placeholder="Select"
          @change="onSelectNewCat"
        />
      </div>

      <div v-if="newIdCat">
        <br/><br/>
        <a-button
          type="primary"
          icon="save"
          size="small"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  data() {
    return {
      value: undefined,
      idCat: undefined,
      featureValue : undefined,
      newIdCat: undefined,
      newFeatureValue : undefined,
      isModified: false,
      targetKeys: [],
      waitServer: false,
      waitMax: 0,
      waitActual: 0,
      leftTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
      rightTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
      featuresName : {
        "10": "city",
        "11": "region",
        "14": "Tissage",
        "15": "Composition",
        "16": "Teinture",
        "17": "Fabrication",
        "18": "Famille",
        "24": "Couleurs",
        "25": "Design",
        "26": "Couleurs 2",
        "27": "Couleurs 3",
      },
    };
  },
  components: {},
  computed: {
    ...mapState(["products", "featuresList", "loading", "language"]),
    waitPercent: function() {
      var percent = (this.waitActual / this.waitMax) * 100;
      return percent.toFixed(0);
    },
    productstoShow() {
      var filter = _.filter(this.products, {
        active: "1",
      });
      var newArr = _.map(filter, function(element) {
        return _.extend({}, element, {
          key: element.id,
          title: element.name.language[0]["_"],
        });
      });
      return newArr;
    },
    productsInCat: function() {
      var idCat = this.idCat;
      console.log(idCat);

      // var filter = _.filter(this.productstoShow, function(item) {
      //   var sFilter = _.some(item.associations.product_features.product_feature, ['_', idCat.toString()]);
      //   return typeof sFilter != "undefined" ? true : false;
      // });
      var filter = _.filter(this.productstoShow, (x) =>
        _.some(x.associations.product_features.product_feature, {
          id_feature_value: { _: idCat.toString() },
        })
      );
      var filterArray = [];
      filter.forEach(function(item) {
        filterArray.push(item.reference);
      });
      return filterArray;
    },
    featuresListSimple: function() {
      var list = [];
      var featuresUsed = [
        "10",
        "11",
        "14",
        "15",
        "16",
        "17",
        "18",
        "24",
        "25",
        "26",
        "27",
      ];
      
      this.featuresList.forEach((e) => {
        if (featuresUsed.includes(e.id_feature["_"])) {
          var num = this.numProductswithFeatures(e.id);
          list.push({
            id: parseInt(e.id),
            cat: this.featuresName[e.id_feature["_"]],
            custom: parseInt(e.custom),
            title: e.value.language[this.language]["_"],
            value: e.id_feature["_"]+"_"+parseInt(e.id),
            order: num,
            label:
              parseInt(e.custom) == 1
                ? "⊗ " + e.value.language[this.language]["_"] + " (" + num + ")"
                : e.value.language[this.language]["_"] + " (" + num + ")",
          });
        }
      });
      list = _.sortBy(list, ["title"]);
      return list;
    },
    featuresListTree: function() {
      console.log(this.products[0]);
      var list = this.featuresListSimple;
      var listO = _.mapValues(_.groupBy(list, "cat"), (clist) =>
        clist.map((car) => _.omit(car, "cat"))
      );
      var newList = [];
      _.forEach(listO, function(value, key) {
        var obj = {
          value: key,
          label: key,
          children: value,
        };
        newList.push(obj);
      });
      return newList;
    },
    featuresListTreeFiltered: function() {
      var list = this.featuresListSimple;
      var listO = _.mapValues(_.groupBy(list, "cat"), (clist) =>
        clist.map((car) => _.omit(car, "cat"))
      );
      var thos = this;
      var newList = [];
      _.forEach(listO, function(value, key) {
        if(key == thos.featuresName[thos.featureValue]) {
        var obj = {
          value: key,
          label: key,
          children: value,
        };
        newList.push(obj);
        }
      });
      return newList;
    },
  },
  methods: {
    numProductswithFeatures: function(id) {
      //associations.product_features.product_feature
      //var filter = _.filter(this.products, { 'associations' : { "product_features" : { "product_feature" : [ 'id_feature_value' : { '_' : id} ]}} });
      var lodash = _.filter(this.productstoShow, (x) =>
        _.some(x.associations.product_features.product_feature, {
          id_feature_value: { _: id },
        })
      );
      return lodash.length;
    },
    onSelectCat(value) {
      var idCat = value[1].split("_");
      this.idCat = idCat[1];
      this.featureValue = idCat[0];
    },
    onSelectNewCat(value) {
      var idCat = value[1].split("_");
      this.newIdCat = idCat[1];
      this.newFeatureValue = idCat[0];
      // this.targetKeys = this.productsInCat;
      console.log(this.idCat);
      console.log(this.newIdCat);
      console.log(this.featureValue);
      console.log(this.newFeatureValue);
      
    },
    handleChange(targetKeys) {
      this.targetKeys = targetKeys;
      this.isModified = true;
    },
    save() {
      var idFeature = this.newFeatureValue,
        idCat = this.newIdCat;
      this.loading = true;
      this.$message.loading({
        content: "Sauvegarde ...",
        key: "productCatEdit",
      });
      this.waitServer = true;
      this.waitMax = this.productsInCat.length;
      console.log(this.productsInCat);
      this.$store
        .dispatch("editProductsFeatures", {
          idCat: idCat,
          idFeature : idFeature,
          products: this.productsInCat
        })
        .then(([id, socket]) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
          this.newIdCat = undefined;
          this.sockets.subscribe(socket, (data) => {
            this.waitActual = data.num;
            if (data.result)
              this.$store.commit("CHANGE_Product", {
                id: data.id,
                data: data.data,
              });
            if (this.waitActual == this.waitMax) {
              this.sockets.unsubscribe(socket);
              this.waitServer = false;
              this.loading = false;
            }
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec de l'édition " + error,
            key: id,
          });
        });
    },
  },
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
