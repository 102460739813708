<template>
  <div id="catPage">
    <!-- Tree Select -->
    <div v-if="waitServer">
      <a-progress :percent="waitPercent" size="small" />
    </div>
    <h2>Visualisation des soldes</h2>

    <div v-for="rule in rules" :key="rule.id">
      <br/><br/>
      <h4>{{ rule.title }}</h4>
      <a-tag
        v-for="item in soldes[rule.id]"
        :key="item.id"
        closable
        @close="() => handleClose(item)"
      >
        {{ item.value }}
      </a-tag>
    </div>
    <!-- <h3>Filtre type feature</h3>
    <a-select dropdownMatchSelectWidth="false" allowClear :placeholder="'Selectionner votre Type de Feature'" style="width: 300px" @change="handleChange">
      <a-select-option v-for="item in featuresNameList" :key="item.value" :value="item.value">
      {{ item.title }}
      </a-select-option>
    </a-select>
    <h3>Sélectionner les Features à supprimer</h3>
    <a-tag
      v-for="item in featuresListSimple"
      :key="item.id"
      closable
      @close="() => handleClose(item)"
      :color="item.order == 0 ? 'green' : 'red'"
    >
      {{ item.cat }} / {{ item.label }} / {{ item.id }}
    </a-tag> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  data() {
    return {
      value: undefined,
      idCat: undefined,
      featureCat: undefined,
      newIdCat: undefined,
      newFeatureValue: undefined,
      isModified: false,
      targetKeys: [],
      waitServer: false,
      waitMax: 0,
      waitActual: 0,
      leftTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
      rightTableColumns: [
        {
          dataIndex: "reference",
          key: "reference",
          title: "Ref",
        },
        {
          dataIndex: "name.language[0]['_']",
          key: "name.language[0]['_']",
          title: "Nom",
        },
      ],
      featuresName: {
        "10": "Ville",
        "11": "region",
        "14": "Tissage",
        "15": "Composition",
        "16": "Teinture",
        "17": "Fabrication",
        "18": "Famille",
        "24": "Couleurs",
        "25": "Design",
        "26": "Couleurs 2",
        "27": "Couleurs 3",
      },
    };
  },
  components: {},
  computed: {
    ...mapState(["productSoldes", "soldesRules", "products", "loading"]),
    rules: function() {
      var rules = [];
      _.forEach(this.soldesRules, function(value) {
        rules.push({
          title: value.name,
          id: value.id,
        });
      });
      rules.push({
        title: "Others",
        id: 0,
      });
      return rules;
    },
    soldes: function() {
      var soldes = {};
      var thos = this;
      _.forEach(this.productSoldes, function(value) {
        if (typeof soldes[value.id_specific_price_rule] == "undefined")
          soldes[value.id_specific_price_rule] = [];
        var ref = _.find(thos.products, { id: value.id_product["_"] });
        if(typeof(ref) != "undefined") {
          soldes[value.id_specific_price_rule].push({
            id: value.id,
            value: ref.reference + " (" + value.id_product["_"] + ")",
          });
        }
      });

      return soldes;
    },
    featuresNameList: function() {
      var list = [];
      _.forEach(this.featuresName, function(value, key) {
        list.push({
          title: value,
          value: key,
        });
      });
      return list;
    },
    waitPercent: function() {
      var percent = (this.waitActual / this.waitMax) * 100;
      return percent.toFixed(0);
    },
    featuresListSimple: function() {
      var list = [];
      var featuresUsed = [
        "10",
        "11",
        "14",
        "15",
        "16",
        "17",
        "18",
        "24",
        "25",
        "26",
        "27",
      ];

      this.featuresList.forEach((e) => {
        if (featuresUsed.includes(e.id_feature["_"])) {
          var num = this.numProductswithFeatures(e.id);
          if (
            typeof this.featureCat == "undefined" ||
            this.featureCat == e.id_feature["_"]
          ) {
            list.push({
              id: e.id,
              cat: this.featuresName[e.id_feature["_"]],
              custom: parseInt(e.custom),
              title: e.value.language[this.language]["_"],
              value: e.id_feature["_"] + "_" + parseInt(e.id),
              order: num,
              label:
                parseInt(e.custom) == 1
                  ? "⊗ " +
                    e.value.language[this.language]["_"] +
                    " (" +
                    num +
                    ")"
                  : e.value.language[this.language]["_"] + " (" + num + ")",
            });
          }
        }
      });
      list = _.sortBy(list, ["cat", "num", "title"]);
      return list;
    },
  },
  methods: {
    numProductswithFeatures: function(id) {
      //associations.product_features.product_feature
      //var filter = _.filter(this.products, { 'associations' : { "product_features" : { "product_feature" : [ 'id_feature_value' : { '_' : id} ]}} });
      var lodash = _.filter(this.products, (x) =>
        _.some(x.associations.product_features.product_feature, {
          id_feature_value: { _: id },
        })
      );
      return lodash.length;
    },
    closeTag(value) {
      console.log(value);
    },
    handleClose(removedTag) {
      console.log(removedTag);
      this.$message.loading({
        content: "Suppression Solde (" + removedTag.title + ")...",
        key: removedTag.id,
      });
      this.$store
        .dispatch("deleteProductSoldes", { id: removedTag.id })
        .then((id) => {
          this.$message.success({ content: "Suppression réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la suppression " + error,
            key: id,
          });
        });
    },
    handleChange(value) {
      this.featureCat = value;
    },
  },
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
