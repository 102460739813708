<template>
  <div class="PosTable">
    <a-table
      class="orders"
      :columns="columns"
      :data-source="wa_Artisan"
      rowKey="id"
      style="{width='100%'; }'"
      :pagination="{
        position: 'both',
        showSizeChanger: true,
        defaultPageSize: 100,
        pageSizeOptions: ['100', '250', '500'],
      }"
    >
      <div slot="value" slot-scope="text, data">
        <span v-if="data.id">
          {{ text | toNum(data) }}
        </span>
        <span v-else
          ><b>{{ text | toNum(data) }}</b></span
        >
      </div>
      <div slot="text" slot-scope="text, data">
        <span v-if="data.id">
          {{ text }}
        </span>
        <span v-else
          ><b>{{ text }}</b></span
        >
      </div>
      <div slot="action" slot-scope="data" :data="data" v-if="data.id">
        <a-tooltip
          v-if="data.idDoli"
          placement="top"
          title="Editer la fiche Fournisseur"
        >
          <a-button
            type=""
            :href="
              'http://factu.host.tribaliste.com/societe/card.php?socid=' +
                data.idDoli +
                '&action=edit'
            "
            target="_blank"
            icon="edit"
          />
        </a-tooltip>
        <a-tooltip v-else placement="top" title="Créer la fiche Fournisseur">
          <a-button
            type=""
            :href="
              'http://factu.host.tribaliste.com/societe/card.php?leftmenu=suppliers&action=create&type=f&supplier_code=' +
                data.codeF +
                '&name=' +
                data.name
            "
            target="_blank"
            icon="user-add"
            style="color : red"
          />
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && data.conversation_id"
          placement="top"
          title="Envoyer le message de bienvenue"
        >
          <a-button
            v-if="!running"
            type=""
            icon="like"
            style="color : green"
            @click="sendWelcome(data)"
          >
          </a-button>
          <a-button v-else type="" icon="loading" style="color : green">
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && data.conversation_id"
          placement="top"
          title="Envoyer le message de changement de langue"
        >
          <a-button
            v-if="!running"
            type=""
            icon="flag"
            style="color : blue"
            @click="sendLang(data)"
          >
          </a-button>
          <a-button v-else type="" icon="loading" style="color : blue">
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && data.conversation_id"
          placement="top"
          title="Envoyer le message d'aide du bot"
        >
          <a-button
            v-if="!running"
            type=""
            icon="question-circle"
            style="color : orange"
            @click="sendHelp(data)"
          >
          </a-button>
          <a-button v-else type="" icon="loading" style="color : orange">
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && data.conversation_id"
          placement="top"
          title="Envoyer l'état paiement"
        >
          <a-button
            v-if="!running"
            type=""
            icon="dollar"
            style="color : green"
            @click="sendFlouss(data)"
          >
          </a-button>
          <a-button v-else type="" icon="loading" style="color : red">
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && data.conversation_id"
          placement="top"
          title="Envoyer une commande factice"
        >
          <a-button
            v-if="!running"
            type=""
            icon="shopping"
            style="color : red"
            @click="sendFakeOrder(data)"
          >
          </a-button>
          <a-button v-else type="" icon="loading" style="color : red">
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="data.idDoli && !data.conversation_id && data.phonetel"
          placement="top"
          title="Envoyer le message d'invitation par SMS"
        >
          <a-button
            v-if="!running"
            type=""
            icon="phone"
            style="color : orange"
            @click="sendSMS(data)"
          >
            SMS
          </a-button>
          <a-button v-else type="" icon="loading" style="color : orange">
            SMS
          </a-button>
        </a-tooltip>
      </div>
    </a-table>
  </div>
</template>

<script>
//import _ from "lodash";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    title: "ID",
  },
  {
    dataIndex: "idDoli",
    key: "idDoli",
    title: "ID Doli",
  },
  {
    dataIndex: "phonetel",
    key: "phonetel",
    title: "Telephone",
  },
  {
    dataIndex: "name",
    key: "name",
    title: "REF",
    scopedSlots: { customRender: "text" },
  },
  {
    dataIndex: "conversation_id",
    key: "conversation_id",
    title: "ID Conversation",
    scopedSlots: { customRender: "text" },
  },
  {
    dataIndex: "conversation_lang",
    key: "conversation_lang",
    title: "Langue Conversation",
    scopedSlots: { customRender: "text" },
  },
  {
    key: "action",
    title: "Actions",
    scopedSlots: { customRender: "action" },
  },
];

import { mapState } from "vuex";
import axios from "axios";
import _ from "lodash";

const zeroPad = (num, places) => String(num).padStart(places, "0");

export default {
  name: "dashboard",
  props: ["type"],
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
      running: false,
    };
  },
  computed: {
    ...mapState(["products", "suppliers", "orders", "language", "loading", "userToken",]),
    wa_Artisan() {
      let newPos = [];
      console.log(this.suppliers[0]);
      this.suppliers.forEach(function(supplier) {
        if (supplier.id != 4) {
          var obj = {
            id: supplier.id,
            name: supplier.name,
          };
          console.log(supplier.doli);
          if (typeof supplier.doli == "object") {
            obj.idDoli = supplier.doli.id;
            obj.phonetel = supplier.doli.phone;
            obj.conversation_id = supplier.doli.array_options.options_savid;
            obj.conversation_lang =
              supplier.doli.array_options.options_savlangue;
          } else {
            obj.codeF = zeroPad(parseInt(supplier.id), 7);
          }
          newPos.push(obj);
        }
      }, this);

      return newPos;
    },
  },
  filters: {
    toNum(value, data) {
      if (value == data.total) {
        return value.toLocaleString();
      } else {
        var pct = (value * 100) / data.total;
        return (
          value.toLocaleString() + " (" + pct.toFixed(0).toLocaleString() + "%)"
        );
      }
    },
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
    sendSMS(data) {
      this.running = true;
      console.log(data);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendInvitation",
          {
            phone : data.phonetel
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('SMS d\'inivtation bien envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
    sendWelcome(data) {
      this.running = true;
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendWelcome",
          {
            conversationId : data.conversation_id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('Message de bienvenue bien envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
    sendLang(data) {
      this.running = true;
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendLang",
          {
            conversationId : data.conversation_id,
            artisanId : data.idDoli
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('Message de bienvenue bien envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
    sendHelp(data) {
      this.running = true;
      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendHelp",
          {
            conversationId : data.conversation_id,
            artisanId : data.id,
            lang : data.conversation_lang
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('Message de bienvenue bien envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
    sendFakeOrder(data) {
      this.running = true;
      console.log(data)
      var sells = [];
      var productsOfSupplier = _.filter(this.products, {
        id_supplier: { _: data.id.toString() },
      });

      sells.push(
        {
          product : {
            discounts : 0,
            detail : productsOfSupplier[0]
          },
          POId : 1
        }
      )
      sells.push(
        {
          product : {
            discounts : 1,
            discounts_pourcentage : -30,
            detail : productsOfSupplier[1]
          },
          POId : 1
        }
      )

      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendFakeOrder",
          {
            conversationId : data.conversation_id,
            artisanId : data.id,
            sells : sells
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('Message de bienvenue bien envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
    sendFlouss(data) {
      this.running = true;
      console.log(data)

      axios
        .post(
          process.env.VUE_APP_APIURL + "/whatsapp/sendFlouss",
          {
            conversationId : data.conversation_id,
            artisanId : data.idDoli,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.running = false;
          this.$message.success('Message d\'état de paiement envoyé !');
        })
        .catch(() => {
          this.running = false;
          this.$message.error('Une erreur est survenue !');
        });
    },
  },
};
</script>

<style lang="scss">
.PosTable {
  width: 90%;
}
</style>
