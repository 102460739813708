<template>
  <div class="filterDropdown" style="padding: 8px">
    <a-input
      v-ant-ref="(c) => (searchInput = c)"
      :placeholder="`Search ${column.dataIndex}`"
      :value="selectedKeys[0]"
      style="width: 188px; margin-bottom: 8px; display: block;"
      @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
      @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
    />
    <a-button
      type="primary"
      icon="search"
      size="small"
      style="width: 90px; margin-right: 8px"
      @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
    >
      Search
    </a-button>
    <a-button
      size="small"
      style="width: 90px"
      @click="() => handleReset(clearFilters)"
    >
      Reset
    </a-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "productDetails",
  props: ["setSelectedKeys", "selectedKeys", "confirm", "clearFilters", "column"],
  computed: {
    ...mapState(["language"]),
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
  }
};
</script>

<style lang="scss">
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
