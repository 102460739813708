<template>
  <div class="filterActive">
    <a-tag v-if="tags == '1'" :key="tags" :color="'green'">
      On-Line
    </a-tag>
    <a-tag v-if="tags == '0'" :key="tags" :color="'orange'">
      Off-Line
    </a-tag>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "filterActive",
  props: ["tags"],
  computed: {
    ...mapState(["language"]),
  },
  methods: {},
};
</script>
