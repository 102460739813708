<template>
  <div class="editable-cell">
    {{ data.customer.firstname }} {{ data.customer.lastname }} 
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "client",
  props: ["data"],
  computed: {
    ...mapState(["language", "loadings"]),
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.editPrice(this.data.id, this.value);
    },
    edit() {
      this.editable = true;
    },
    editPrice(id, value) {
      this.$message.loading({
        content: "Edition prix (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editProduct", { id: id, action: "price", value: value })
        .then((id) => {
          this.$message.success({ content: "Modification réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la modification " + error,
            key: id,
          });
        });
    },
  },
};
</script>
