<template>
  <div id="traductionPage">
    <a-tabs default-active-key="2">
      <a-tab-pane
        key="2"
        :tab="'Visualisation soldes'"
      >
        <visualisationSoldes />
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="'Ajout par ID'"
      >
        <massEditSoldes/>
      </a-tab-pane>
      <a-tab-pane
        key="4"
        :tab="'Déplacer dans la catégorie'"
        :disabled="needData_meta_description.length == 0"
      >
        <catSoldes
        />
      </a-tab-pane>
      <a-tab-pane
        key="5"
        :tab="'Retirer de la catégorie'"
        :disabled="needData_meta_description.length == 0"
      >
        <catSoldesRemove
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import visualisationSoldes from "./soldes/visualisation.vue";
import catSoldes from "./soldes/productsCat.vue";
import catSoldesRemove from "./soldes/productsCatRemove.vue";
import massEditSoldes from "./soldes/soldesMassEdit.vue";
import { mapState } from "vuex";

export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        title: true,
        description: true,
        meta_title: true,
        meta_description: true,
      },
    };
  },
  components: {
    catSoldes,
    catSoldesRemove,
    massEditSoldes,
    visualisationSoldes
  },
  computed: {
    ...mapState(["products", "language", "loading"]),
    needData_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.description_short.language[0]["_"] == "undefined") {
            needTrad.push(product);
          } else if (product.description_short.language[0]["_"] == "") needTrad.push(product);
      });
      return needTrad;
    },
    needData_meta_title() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.meta_title.language[0]["_"] == "undefined")
            needTrad.push(product)
          else if (product.meta_title.language[0]["_"] == "") needTrad.push(product);
      });
      return needTrad;
    },
    needData_meta_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if(product.active == 1 && product.stock.quantity > 0)
          if (typeof product.meta_description.language[0]["_"] == "undefined")
            needTrad.push(product)
          else if (product.meta_description.language[0]["_"] == "")
              needTrad.push(product);
      });
      return needTrad;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
