<template>
  <div id="ordersPage">
    <a-tabs default-active-key="2">
      <a-tab-pane
        key="1"
        :tab="'Need Admin (' + orders_step10.length + ')'"
        :disabled="orders_step10.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step10" />
      </a-tab-pane>
      <a-tab-pane
        key="2"
        :tab="'New (' + orders_step1.length + ')'"
        :disabled="orders_step1.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step1" />
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="'Capture (' + orders_step2.length + ')'"
        :disabled="orders_step2.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step2" />
        <div v-if="loading.transmissionCapture">
          <a-button type="danger" icon="loading">
            Transmettre en cours
          </a-button>
        </div>
        <div v-else>
          <a-popconfirm
            title="Are you sure execute this task?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="transmissionCapture"
          >
            <a-button type="danger" icon="forward">
              Transmettre les transactions pour la capture
            </a-button></a-popconfirm
          >
        </div>
      </a-tab-pane>
      <a-tab-pane
        key="4"
        :tab="'Acheminement. (' + orders_step3.length + ')'"
        :disabled="orders_step3.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step3" />
      </a-tab-pane>
      <a-tab-pane
        key="5"
        :tab="'Receptionné. (' + orders_step4.length + ')'"
        :disabled="orders_step4.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step4" />
      </a-tab-pane>
      <a-tab-pane
        key="6"
        :tab="'Préparation (' + orders_step5.length + ')'"
        :disabled="orders_step5.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step5" />
      </a-tab-pane>
      <a-tab-pane
        key="7"
        :tab="'Collecte. (' + orders_step6.length + ')'"
        :disabled="orders_step6.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step6" />
      </a-tab-pane>
      <a-tab-pane
        key="8"
        :tab="'Expedié (' + orders_step7.length + ')'"
        :disabled="orders_step7.length == 0"
      >
        <tableOrders :type="'expedie'" :orders="orders_step7" />
      </a-tab-pane>
      <a-tab-pane
        key="9"
        :tab="'Livré (' + orders_step8.length + ')'"
        :disabled="orders_step8.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step8" />
      </a-tab-pane>
      <a-tab-pane
        key="10"
        :tab="'Annulé/Remboursé (' + orders_step9.length + ')'"
        :disabled="orders_step9.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders_step9" />
      </a-tab-pane>
      <a-tab-pane
        key="99"
        :tab="'ALL (' + orders.length + ')'"
        :disabled="orders.length == 0"
      >
        <tableOrders :type="'all'" :orders="orders" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import tableOrders from "./ordersTable";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        transmissionCapture: false,
      },
    };
  },
  components: {
    tableOrders,
  },
  computed: {
    ...mapState(["orders", "language", "loading", "userToken"]),
    orders_step1() {
      //new order
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Paiement accepté")
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step2() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (
          order.state.name.language[0]["_"] == "Validée par l'artisan" ||
          order.state.name.language[0]["_"] == "Refusée par l'artisan"
        )
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step3() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Acheminement en cours")
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step4() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Réception chez Tribaliste")
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step5() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "En cours de préparation")
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step6() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "En attente de collecte")
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step7() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Expédié")
          needTrad.push(order);
      });
      console.log("here");
      console.log(needTrad)
      return needTrad;
    },
    orders_step8() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Livré") needTrad.push(order);
      });
      return needTrad;
    },
    orders_step9() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (
          order.state.name.language[0]["_"] == "Annulé" ||
          order.state.name.language[0]["_"] == "Remboursé"
        )
          needTrad.push(order);
      });
      return needTrad;
    },
    orders_step10() {
      let needTrad = [];
      this.orders.forEach(function(order) {
        if (order.state.name.language[0]["_"] == "Need admin")
          needTrad.push(order);
      });
      return needTrad;
    },
  },
  methods: {
    transmissionCapture() {
      if (!this.loading.transmissionCapture) {
        this.loading.transmissionCapture = true;
        this.$message.loading({
          content: "Transmission des captures",
          key: "transmission_capture",
        });
        console.log(this.orders_step2);
        axios
          .post(
            process.env.VUE_APP_APIURL + "/orders/capture",
            {
              data: this.orders_step2,
            },
            {
              headers: {
                Authorization: `Bearer ${this.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then(() => {
            this.orders_step2.forEach(function(order) {
              var action = "acheminement";
              if (order.current_state["_"] == "28") action = "refund";
              this.$store
                .dispatch("editOrder", { id: order.id, action: action })
                .then(() => {
                  this.$message.success({
                    content: "Capture réussi",
                    key: "transmission_capture",
                  });
                })
                .catch(([error, id]) => {
                  console.log(error);
                  this.$message.error({
                    content: "Echec  " + error,
                    key: id,
                  });
                });
            }, this);
            this.loading.transmissionCapture = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading.transmissionCapture = false;
            this.$message.error({
              content: "Echec  " + error,
              key: "transmission_capture",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
#ordersPage {
  width: 100%;
}
</style>
