<template>
  <div id="productsTable">
    <export-excel :data="productsXLS" worksheet="Produits" name="products.xls">
      <a-button type="primary" icon="download">
        Télécharger au format Excel
      </a-button>
    </export-excel>
    <a-table
      class="resultCV"
      :columns="columns"
      :data-source="products"
      rowKey="id"
      style="{width='100%'; }'"
      :pagination="{ position: 'both', 'show-size-changer': 'true' }"
    >
      <filterDropdown
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        :setSelectedKeys="setSelectedKeys"
        :selectedKeys="selectedKeys"
        :confirm="confirm"
        :clearFilters="clearFilters"
        :column="column"
      />
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <priceCell
        slot="price"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <priceArtisanCell
        slot="priceArtisan"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <stockCell
        slot="stock"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <filterSearch
        slot="customRender"
        slot-scope="text, record, index, column"
        :text="text"
        :record="record"
        :index="index"
        :column="column"
      />
      <imageCell
        slot="image"
        slot-scope="text"
        :text="text"
        :showModal="showModal"
      />
      <filterActive slot="actif" slot-scope="tags" :tags="tags" />
      <productDetails
        slot="expandedRowRender"
        slot-scope="record"
        :record="record"
        style="margin: 0"
      />
      <actionsCell slot="action" slot-scope="data" :data="data" />
    </a-table>
    <a-modal v-model="imgModal" width="50%" :footer="null">
      <img :src="imgUrl" width="100%" />
    </a-modal>
  </div>
</template>

<script>
import productDetails from "./table/productDetails";
import filterDropdown from "./table/filterDropdown";
import filterSearch from "./table/search";
import imageCell from "./table/image";
import filterActive from "./table/filterActive";
import actionsCell from "./table/actions";
import priceCell from "./table/price";
import priceArtisanCell from "./table/priceArtisan";
import stockCell from "./table/stock";

// import _ from "lodash";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    title: "ID",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
    sorter: (a, b) => a.id - b.id,
    sortDirections: ["descend", "ascend"],
  },
  {
    dataIndex: "reference",
    key: "reference",
    title: "REF",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.reference
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
  {
    dataIndex: "id_default_image['_']",
    key: "id_default_image['_']",
    title: "#",
    scopedSlots: { customRender: "image" },
  },
  {
    dataIndex: "name.language[0]['_']",
    key: "name.language[0]['_']",
    title: "Nom",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.name.language[0]["_"]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
  {
    dataIndex: "price",
    key: "price",
    title: "Prix",
    sorter: (a, b) => a.price - b.price,
    scopedSlots: { customRender: "price" },
  },
  {
    dataIndex: "wholesale_price",
    key: "wholesale_price",
    title: "Prix Artisan",
    sorter: (a, b) => a.wholesale_price - b.wholesale_price,
    scopedSlots: { customRender: "priceArtisan" },
  },
  {
    dataIndex: "stock.quantity",
    key: "stock",
    title: "Stock",
    sorter: (a, b) => a.stock.quantity - b.stock.quantity,
    scopedSlots: { customRender: "stock" },
    filters: [
      {
        text: "Hors Stock",
        value: "0",
      },
      {
        text: "En stock",
        value: ">0",
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => {
      if (value == "0") return record.stock.quantity == 0;
      else return record.stock.quantity > 0;
    },
  },
  {
    dataIndex: "active",
    key: "active",
    title: "Actif",
    scopedSlots: { customRender: "actif" },
    filters: [
      {
        text: "On-Line",
        value: "1",
      },
      {
        text: "Off-Line",
        value: "0",
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.active.indexOf(value) === 0,
    sorter: (a, b) => a.active - b.active,
    sortDirections: ["descend", "ascend"],
  },

  {
    dataIndex: "id_supplier['_']",
    key: "id_supplier['_']",
    title: "Artisan",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.supplier_id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },

  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
    };
  },
  components: {
    productDetails,
    filterDropdown,
    filterSearch,
    imageCell,
    filterActive,
    actionsCell,
    priceCell,
    priceArtisanCell,
    stockCell,
  },
  computed: {
    ...mapState(["products", "language", "loading"]),
    productsXLS() {
      var P = [];
      console.log(this.products[0]);
      this.products.forEach(function(product) {
        var p = _.clone(product);
        p.id_supplier =
          typeof product.id_supplier != "undefined"
            ? product.id_supplier["_"]
            : "0";
        p.id_category_default =
          typeof product.id_category_default != "undefined"
            ? product.id_category_default["_"]
            : "0";
        p.quantity =
          typeof product.stock != "undefined" ? product.stock.quantity : "0";
        p.name = product.name.language[0]['_']
        delete p.id_default_image;
        delete p.id_default_combination;
        delete p.id_tax_rules_group;
        delete p.position_in_category;
        delete p.manufacturer_name;
        delete p.type;
        delete p.meta_description;
        delete p.meta_keywords;
        delete p.meta_title;
        delete p.link_rewrite;
        delete p.description;
        delete p.description_short;
        delete p.available_now
        delete p.available_later;
        delete p.associations
        delete p.name_category_default
        delete p.stock
        P.push(p);
      });
      return P;
    },
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
  },
};
</script>

<style lang="scss">
#productsTable {
  width: 90%;
}
</style>
