<template>
  <div class="categories">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="notedit" />
      <a-button type="primary" icon="save" size="small" @click="saveCat(record.id)" v-if="selectedKeys != initialSelectedKeys"/>
      <a-tree
        v-model="selectedKeys"
        checkable
        :tree-data="categoriesTree"
        :auto-expand-parent="autoExpandParent"
        :expanded-keys="expandedKeys"
        @expand="onExpand"
        @select="onSelect"
        checkStrictly
      />
    </div>
    <div v-else>
      <a-tag v-for="cat in record.associations.categories.category" :key="cat">
        {{ cat.value.language[language]["_"] }}
      </a-tag>
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "features",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      initialSelectedKeys : []
    };
  },
  computed: {
    ...mapState(["language", "categoriesList"]),
    selectedCategoriesID: function() {
      var list = [];
      this.record.associations.categories.category.forEach((e) => {
        list.push(parseInt(e.id));
      });
      return list;
    },
    expandedCategoriesID: function() {
      var parent = [];
      this.record.associations.categories.category.forEach((e) => {
        var cat = _.filter(this.categoriesSimple, { id: parseInt(e.id) });
        if (cat.length > 0) {
          let pId = cat[0]["parentId"];
          parent.push(pId);
        }
      });
      return _.uniq(parent);
    },
    categoriesSimple: function() {
      var list = [];
      this.categoriesList.forEach((e) => {
        if (e.level_depth != "0") {
          list.push({
            id: parseInt(e.id),
            key: parseInt(e.id),
            parentId: (parseInt(e.id_parent["_"]) < 2)
              ? 0
              : parseInt(e.id_parent["_"]),
            title: e.name.language[this.language]["_"],
            level: parseInt(e.level_depth) - 1,
            children: null,
          });
        }
      });
      return list;
    },
    categoriesTree: function() {
      console.log(this.categoriesSimple)
      var list = this.categoriesSimple;
      var map = {},
        node,
        roots = [],
        i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== 0) {
          // if you have dangling branches check that map[node.parentId] exists
          if(typeof(list[map[node.parentId]]) != 'undefined') {            
            list[map[node.parentId]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
  },
  mounted() {
    this.selectedKeys = this.selectedCategoriesID;
    this.initialSelectedKeys = this.selectedCategoriesID;
    this.expandedKeys = this.expandedCategoriesID;
  },
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    saveCat(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification catégories (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "editCategories",
          id: idProduct,
          categories : this.selectedKeys.checked
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    },
  },
};
</script>

<style lang="scss">
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
