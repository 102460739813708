<template>
  <a-layout-footer style="textalign: center">
    ©MOTribaliste by Laruche - Middle Office Prestashop
  </a-layout-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
