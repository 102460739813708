<template>
  <div class="product_description">
    <a-popconfirm
      title="êtes vous sur ?"
      ok-text="Yes"
      cancel-text="No"
      @confirm="outsiteSale"
    >
      <a-button type="danger" icon="forward">
        Produit vendu hors Site !
      </a-button>
    </a-popconfirm>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "product",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      edited: false,
      initialValues: [],
      editableValues: [],
    };
  },
  computed: {
    ...mapState(["user", "language", "languageDetail", "featuresList"]),
  },
  components: {},
  methods: {
    outsiteSale() {
      var idProduct = this.record.id
      var key = idProduct;
      this.$message.loading({
        content: "Modification Vente hors site (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "editValue",
          subAction: "upc",
          id: idProduct,
          content: "000000000000",
          multi : true
        })
        .then(() => {
          this.$store
            .dispatch("editProductStock", { id: idProduct, value: 0 })
            .then((id) => {
              this.$message.success({
                content: "Modification réussi",
                key: id,
              });
            })
            .catch(([error, id]) => {
              this.$message.error({
                content: "Echet de la modification " + error,
                key: id,
              });
            });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}</style
>>
