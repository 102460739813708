var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productsTable"},[_c('export-excel',{attrs:{"data":_vm.orders,"worksheet":"Rentabilite","name":"renta.xls"}},[_c('a-button',{attrs:{"type":"primary","icon":"download"}},[_vm._v(" Télécharger au format Excel ")])],1),_c('a-table',{staticClass:"orders",staticStyle:{},attrs:{"columns":_vm.columns,"data-source":_vm.orders,"rowKey":"ref","pagination":{
      position: 'both',
      showSizeChanger: true,
      defaultPageSize: 1000,
      pageSizeOptions: ['300', '500', '1000'],
    }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function(ref){
      var setSelectedKeys = ref.setSelectedKeys;
      var selectedKeys = ref.selectedKeys;
      var confirm = ref.confirm;
      var clearFilters = ref.clearFilters;
      var column = ref.column;
return _c('filterDropdown',{attrs:{"setSelectedKeys":setSelectedKeys,"selectedKeys":selectedKeys,"confirm":confirm,"clearFilters":clearFilters,"column":column}})}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"client",fn:function(record){return _c('clientCell',{attrs:{"data":record}})}},{key:"priceMad",fn:function(text, data){return _c('priceMad',{attrs:{"text":text,"data":data}})}},{key:"pourcent",fn:function(text, data){return _c('pourcent',{attrs:{"text":text,"data":data}})}},{key:"soldes",fn:function(text, data){return _c('soldes',{attrs:{"text":text,"data":data}})}},{key:"state",fn:function(text, data){return _c('stateCell',{attrs:{"text":text,"data":data}})}},{key:"ref",fn:function(text, data){return _c('refCell',{attrs:{"text":text,"data":data}})}},{key:"expandedRowRender",fn:function(record){return _c('orderDetails',{staticStyle:{"margin":"0"},attrs:{"record":record.all}})}}])}),_c('a-modal',{attrs:{"width":"50%","footer":null},model:{value:(_vm.imgModal),callback:function ($$v) {_vm.imgModal=$$v},expression:"imgModal"}},[_c('img',{attrs:{"src":_vm.imgUrl,"width":"100%"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }