<template>
  <div id="traductionPage">
    <a-tabs default-active-key="1">
      <a-tab-pane
        key="1"
        :tab="'Name (' + needTrad_title.length + ')'"
        :disabled="needTrad_title.length == 0"
      >
        <tableTrad :keyName="'name'" :type="'Titre'" :data="needTrad_title" :userToken=userToken />
      </a-tab-pane>
      <a-tab-pane
        key="2"
        :tab="'Description (' + needTrad_description.length + ')'"
        :disabled="needTrad_description.length == 0"
      >
        <tableTrad
          :keyName="'description_short'"
          :type="'Description'"
          :data="needTrad_description"
          :userToken=userToken
        />
      </a-tab-pane>
      <a-tab-pane
        key="5"
        :tab="'link_rewrite (' + needTrad_link.length + ')'"
        :disabled="needTrad_link.length == 0"
      >
        <tableTrad
          :keyName="'link_rewrite'"
          :type="'Link'"
          :data="needTrad_link"
          :userToken=userToken
        />
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="'Meta Title(' + needTrad_meta_title.length + ')'"
        :disabled="needTrad_meta_title.length == 0"
      >
        <tableTrad
          :keyName="'meta_title'"
          :type="'Meta Title'"
          :data="needTrad_meta_title"
          :userToken=userToken
        />
      </a-tab-pane>
      <a-tab-pane
        key="4"
        :tab="'Meta Description(' + needTrad_meta_description.length + ')'"
        :disabled="needTrad_meta_description.length == 0"
      >
        <tableTrad
          :keyName="'meta_description'"
          :type="'Meta Description'"
          :data="needTrad_meta_description"
          :userToken=userToken
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import tableTrad from "./trad/table";

import { mapState } from "vuex";

export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        title: true,
        description: true,
        meta_title: true,
        meta_description: true,
      },
    };
  },
  components: {
    tableTrad,
  },
  computed: {
    ...mapState(["products", "language", "loading", "userToken"]),
    needTrad_title() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if (
          product.name.language[0]["_"] == product.name.language[1]["_"] ||
          product.name.language[1]["_"] == ""
        )
          needTrad.push(product);
      });
      return needTrad;
    },
    needTrad_link() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if (
          product.link_rewrite.language[0]["_"] == product.link_rewrite.language[1]["_"] ||
          product.link_rewrite.language[1]["_"] == ""
        )
          needTrad.push(product);
      });
      return needTrad;
    },
    needTrad_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if (typeof product.description_short.language[0]["_"] != "undefined") {
          if (
            product.description_short.language[0]["_"] ==
              product.description_short.language[1]["_"] ||
            product.description_short.language[1]["_"] == undefined
          )
            needTrad.push(product);
        }
      });
      return needTrad;
    },
    needTrad_meta_title() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if (typeof product.meta_title.language[0]["_"] != "undefined")
          if (
            product.meta_title.language[0]["_"] ==
              product.meta_title.language[1]["_"] ||
            product.meta_title.language[1]["_"] == ""
          )
            needTrad.push(product);
      });
      return needTrad;
    },
    needTrad_meta_description() {
      let needTrad = [];
      this.products.forEach(function(product) {
        if (typeof product.meta_description.language[0]["_"] != "undefined")
          if (
            product.meta_description.language[0]["_"] ==
              product.meta_description.language[1]["_"] ||
            product.meta_description.language[1]["_"] == ""
          )
            needTrad.push(product);
      });
      return needTrad;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
