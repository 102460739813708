<template>
  <div class="productsTable">
    <export-excel :data="orders" worksheet="Rentabilite" name="renta.xls">
      <a-button type="primary" icon="download">
        Télécharger au format Excel
      </a-button>
    </export-excel>
    <a-table
      class="orders"
      :columns="columns"
      :data-source="orders"
      rowKey="ref"
      style="{width='100%'; }'"
      :pagination="{
        position: 'both',
        showSizeChanger: true,
        defaultPageSize: 1000,
        pageSizeOptions: ['300', '500', '1000'],
      }"
    >
      <filterDropdown
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        :setSelectedKeys="setSelectedKeys"
        :selectedKeys="selectedKeys"
        :confirm="confirm"
        :clearFilters="clearFilters"
        :column="column"
      />
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <clientCell slot="client" slot-scope="record" :data="record" />
      <priceMad
        slot="priceMad"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <pourcent
        slot="pourcent"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <soldes slot="soldes" slot-scope="text, data" :text="text" :data="data" />
      <stateCell
        slot="state"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <refCell slot="ref" slot-scope="text, data" :text="text" :data="data" />
      <orderDetails
        slot="expandedRowRender"
        slot-scope="record"
        :record="record.all"
        style="margin: 0"
      />
    </a-table>
    <a-modal v-model="imgModal" width="50%" :footer="null">
      <img :src="imgUrl" width="100%" />
    </a-modal>
  </div>
</template>

<script>
import soldes from "./table/soldes";
import priceMad from "./table/priceMad";
import pourcent from "./table/pricePct";
import clientCell from "./table/client";
import stateCell from "./table/state";
import refCell from "./table/ref";
import orderDetails from "./table/orderDetails";

//import _ from "lodash";

const columns = [
  {
    dataIndex: "ref",
    key: "ref",
    title: "REF",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.reference
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
  {
    dataIndex: "date_add",
    key: "date_add",
    title: "Date",
    sorter: (a, b) => a.date_add - b.date_add,
  },
  {
    key: "ca_mad",
    dataIndex: "ca_mad",
    title: "CA dhs",
    scopedSlots: { customRender: "priceMad" },
  },
  {
    key: "po_artisan",
    dataIndex: "po_artisan",
    title: "PO Artisan",
    scopedSlots: { customRender: "priceMad" },
  },
  {
    key: "po_delivery",
    dataIndex: "po_delivery",
    title: "Livraison",
    scopedSlots: { customRender: "priceMad" },
  },
  {
    key: "po_payment",
    dataIndex: "po_payment",
    title: "Frais payment",
    scopedSlots: { customRender: "priceMad" },
  },
  {
    dataIndex: "soldes",
    key: "soldes",
    title: "Soldes",
    scopedSlots: { customRender: "soldes" },
  },
  {
    dataIndex: "marge_brut",
    key: "marge_brut",
    title: "Marge Brut",
    sorter: (a, b) => a.marge_brut - b.marge_brut,
    scopedSlots: { customRender: "priceMad" },
  },
  {
    dataIndex: "renta",
    key: "renta",
    title: "% Renta",
    sorter: (a, b) => a.renta - b.renta,
    scopedSlots: { customRender: "pourcent" },
  },
];

import { mapState } from "vuex";

export default {
  name: "dashboard",
  props: ["type", "orders"],
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
    };
  },
  components: {
    soldes,
    priceMad,
    pourcent,
    clientCell,
    refCell,
    stateCell,
    orderDetails,
  },
  computed: {
    ...mapState(["language", "loading"]),
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
  },
};
</script>

<style lang="scss">
.productsTable {
  width: 90%;
}
</style>
