<template>
  <div id="profileCHeck">
    <div class="back" v-if="$route.path.substr(0,6) == '/admin'">
      <router-view></router-view>
    </div>
    <div class="front" v-else>
      <div class="content" v-if="!$auth.loading">
        <a-layout id="dashboard-layout" v-if="$auth.isAuthenticated">
          <Menu></Menu>
          <a-layout>
            <a-layout-content :style="{ margin: '24px 16px 0' , overflow: 'initial'  }">
              
                <router-view></router-view>
            </a-layout-content>
            <Footer></Footer>
            
          </a-layout>
        </a-layout>
        <div v-else>
          <router-view></router-view>
        </div>
      </div>
      <div class="loading" v-else>
        <a-row class="content" type="flex" justify="space-around" align="middle">
          <a-col :span="4">
            <a-spin tip="Chargement..." size="large"></a-spin>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>

import Menu from './components/layout/Menu';
import Footer from './components/layout/Footer';

export default {
  name: 'app',
  components: {
    Menu,
    Footer,
  }
};
</script>

<style lang="scss">
.loading {
  height: 100vh;
  .content {
    height: 100%;
    text-align: center;
  }
}
#dashboard-layout {
min-height: 100vh;

}
</style>
