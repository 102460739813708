<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input
        :value="value * 1"
        @change="handleChange"
        @pressEnter="check"
      /><a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value * 1 || " " }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
      <div v-if="isOnSolde">
        <a-tag v-for="item in reducPercent"
          :key="item.id" color="#87d068">
          -{{ item.reduction * 100 }} %
        </a-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "price",
  props: ["text", "data"],
  computed: {
    ...mapState(["productSoldes", "language", "loadings"]),
    isOnSolde: function() {
      var thos = this;
      var ref = _.filter(this.productSoldes, function(solde) {
        return solde.id_product["_"] == thos.data.id;
      });
      if (ref.length > 0) return true;
      else return false;
    },
    reducPercent: function() {
      var thos = this;
      var ref = _.filter(this.productSoldes, function(solde) {
        return solde.id_product["_"] == thos.data.id;
      });
      return ref;
    },
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.editPrice(this.data.id, this.value);
    },
    edit() {
      this.editable = true;
    },
    editPrice(id, value) {
      this.$message.loading({
        content: "Edition prix (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editProduct", { id: id, action: "price", value: value })
        .then((id) => {
          this.$message.success({ content: "Modification réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la modification " + error,
            key: id,
          });
        });
    },
  },
};
</script>
