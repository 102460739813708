<template>
  <div class="PosTable">
    <export-excel :data="stock_Organize" worksheet="Artisans" name="artisans.xls">
      <a-button type="primary" icon="download">
        Télécharger au format Excel
      </a-button>
    </export-excel>
    <a-table
      class="orders"
      :columns="columns"
      :data-source="stock_Organize"
      rowKey="id"
      style="{width='100%'; }'"
      :pagination="{
        position: 'both',
        showSizeChanger: true,
        defaultPageSize: 100,
        pageSizeOptions: ['100', '250', '500'],
      }"
    >
      <div slot="value" slot-scope="text, data">
        <span v-if="data.id">
          {{ text | toNum(data) }}
        </span>
        <span v-else
          ><b>{{ text | toNum(data) }}</b></span
        >
      </div>
      <div slot="text" slot-scope="text, data">
        <span v-if="data.id">
          {{ text }}
        </span>
        <span v-else
          ><b>{{ text }}</b></span
        >
      </div>
      <div slot="action" slot-scope="data" :data="data" v-if="data.id">
        <a-tooltip placement="top" title="Facturer les commandes">
          <a-button
            type=""
            :href="
              'http://factu.host.tribaliste.com/fourn/commande/orderstoinvoice.php?socid=' +
                data.id
            "
            target="_blank"
            icon="file-done"
          />
        </a-tooltip>
      </div>
    </a-table>
  </div>
</template>

<script>
//import _ from "lodash";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    title: "ID",
  },
  {
    dataIndex: "name",
    key: "name",
    title: "REF",
    scopedSlots: { customRender: "text" },
  },
  {
    dataIndex: "on_stock",
    key: "on_stock",
    title: "En stock",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "out_stock",
    key: "out_stock",
    title: "Hors Stock",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "remove",
    key: "remove",
    title: "Vendu hors Tribaliste",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "inactif",
    key: "inactif",
    title: "Inactif",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "total",
    key: "total",
    title: "total",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "order",
    key: "order",
    title: "Commandes",
  },
];

import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  props: ["type"],
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
    };
  },
  computed: {
    ...mapState(["products", "suppliers", "orders", "language", "loading"]),
    stock_Organize() {
      let newPos = [];
      let detail = {};
      console.log(this.orders[0]);
      this.products.forEach(function(po) {
        if (typeof po.id_supplier["_"] != "undefined") {
          var index = _.findIndex(newPos, {
            id: parseInt(po.id_supplier["_"]),
          });
          if (index == -1) {
            var supplier = _.find(this.suppliers, { id: po.id_supplier["_"] });
            detail = {
              name: supplier.name,
              id: parseInt(po.id_supplier["_"]),
              on_stock: 0,
              inactif: 0,
              out_stock: 0,
              remove: 0,
              total: 0,
              order: 0,
            };
            if (po.active == "1") {
              if(typeof(po.stock) != 'undefined') {
                if (parseInt(po.stock.quantity) > 0) {
                  detail.on_stock++;
                } else {
                  if (po.upc == "000000000000") detail.remove++;
                  detail.out_stock++;
                }
              }
            } else detail.inactif++;
            detail.total++;
            newPos.push(detail);
          } else {
            detail = _.clone(newPos[index]);
            if (po.active == "1") {
              if(typeof(po.stock) != 'undefined') {
                if (parseInt(po.stock.quantity) > 0) {
                  detail.on_stock++;
                } else {
                  if (po.upc == "000000000000") detail.remove++;
                  detail.out_stock++;
                }
              }
            } else detail.inactif++;
            detail.total++;

            newPos[index] = detail;
          }
        }
      }, this);

      newPos.forEach(function(supplier, index) {
        console.log(supplier.id);
        var thos = this;
        var filter = _.filter(this.orders, function(x) {
          if (thos.$moment(x.date_add).isAfter("2020-08-31") && thos.$moment(x.date_add).isBefore("2021-03-01"))
            return _.includes(x.supplier, supplier.id.toString());
          else return false;
        });
        newPos[index]["order"] = filter.length;
      }, this);
      var total = {
        name: "Total",
        on_stock: 0,
        out_stock: 0,
        inactif: 0,
        remove: 0,
        facture: 0,
        total: 0,
        order: 0,
      };
      newPos.forEach(function(po) {
        total.on_stock += po.on_stock;
        total.out_stock += po.out_stock;
        total.inactif += po.inactif;
        total.remove += po.remove;
        total.total += po.total;
        total.order += po.order;
      });
      newPos.push(total);

      return _.orderBy(newPos, ["id"], ["asc"]);
    },
  },
  filters: {
    toNum(value, data) {
      if (value == data.total) {
        return value.toLocaleString();
      } else {
        var pct = (value * 100) / data.total;
        return (
          value.toLocaleString() + " (" + pct.toFixed(0).toLocaleString() + "%)"
        );
      }
    },
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
  },
};
</script>

<style lang="scss">
.PosTable {
  width: 90%;
}
</style>
