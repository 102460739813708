<template>
  <div class="product_description">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="notedit" />
      <a-button
        type="primary"
        icon="save"
        size="small"
        @click="save(record.id)"
        v-if="edited"
      />
      <a-select
        :default-value="record.redirect_type"
        @change="handleChange"
        style="width: 80%"
      >
        <a-select-option value="404">Pas de redirection (404)</a-select-option>
        <a-select-option value="301"
          >Redirection permanente (301)</a-select-option
        >
        <a-select-option value="302"
          >Redirection temporaire (302)</a-select-option
        >
        <a-select-option value="410">Gone (410)</a-select-option>
        <a-select-option value="1301" selected="selected"
          >Redirect parent category permanently (301)</a-select-option
        >
        <a-select-option value="1302"
          >Redirect parent category temporarily (302)</a-select-option
        >
        <a-select-option value="9301"
          >Redirect to home permanently (301)</a-select-option
        >
        <a-select-option value="9302"
          >Redirect to home temporarily (302)</a-select-option
        >
      </a-select>

    </div>
    <div v-else>
      {{ record.redirect_type }}
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "redirect",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      edited: false,
    };
  },
  computed: {
    ...mapState(["user", "language", "languageDetail", "featuresList"]),
  },
  components: {},
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    save(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Redirection (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "editValue",
          subAction: "redirect_type",
          id: idProduct,
          content: this.record.redirect_type,
          subContent : 17
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    handleChange(e) {
      this.record.redirect_type = e;
      this.edited = true;
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}</style
>>
