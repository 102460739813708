<template>
  <div class="product_description">
    <div v-if="editableWidth" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="noteditWidth" />
      <a-button type="primary" icon="save" size="small" @click="saveWidth(record.id)" v-if="editedWidth"/>
      <a-input
        :value="record.width * 1"
        @change="handleChangeWidth($event)"
      />
    </div>
    <div v-else>
      Largeur : {{ record.width * 1 }} cm <a-button icon="edit" size="small" @click="editWidth" /><br/>
    </div>
    <div v-if="editableHeight" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="noteditHeight" />
      <a-button type="primary" icon="save" size="small" @click="saveHeight(record.id)" v-if="editedHeight"/>
      <a-input
        :value="record.height * 1"
        @change="handleChangeHeight($event)"
      />
    </div>
    <div v-else>
      Hauteur : {{ record.height * 1 }} cm <a-button icon="edit" size="small" @click="editHeight" /><br/>
    </div>
    <div v-if="editableDepth" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="noteditDepth" />
      <a-button type="primary" icon="save" size="small" @click="saveDepth(record.id)" v-if="editedDepth"/>
      <a-input
        :value="record.depth * 1"
        @change="handleChangeDepth($event)"
      />
    </div>
    <div v-else>
      Epaisseur / Profondeur : {{ record.depth * 1 }} cm<a-button icon="edit" size="small" @click="editDepth" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "product",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editableWidth: false,
      editableHeight: false,
      editableDepth: false,
      editedWidth: false,
      editedHeight: false,
      editedDepth: false,
      initialValues : [],
      editableValues : []
    };
  },
  computed: {
    ...mapState(["user","language","languageDetail", "featuresList"]),
  },
  components: {},
  methods: {
    editWidth() {
      this.editableWidth = true;
    },
    editHeight() {
      this.editableHeight = true;
    },
    editDepth() {
      this.editableDepth = true;
    },
    noteditWidth() {
      this.editableWidth = false;
    },
    noteditHeight() {
      this.editableHeight = false;
    },
    noteditDepth() {
      this.editableDepth = false;
    },
    saveWidth(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Width (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "width",
          id: idProduct,
          value : this.record.width
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.editedWidth = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    saveHeight(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Height (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "height",
          id: idProduct,
          value : this.record.height
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.editedHeight = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    saveDepth(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification Depth (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "depth",
          id: idProduct,
          value : this.record.depth
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.editedDepth = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    handleChangeWidth(e) {
      this.record.width = e.target.value
      this.editedWidth = true
    },
    handleChangeHeight(e) {
      this.record.height = e.target.value
      this.editedHeight = true
    },
    handleChangeDepth(e) {
      this.record.depth = e.target.value
      this.editedDepth = true
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}
</style>>