<template>
  <div class="imageCell">
    <a-avatar
      v-if="text"
      shape="square"
      :size="small"
      :src="
        'https://www.tribaliste.com/' +
          text +
          '-home_default/azilal-toile-printaniere.jpg'
      "
      @click="
        showModal(
          'https://www.tribaliste.com/' +
            text +
            '-home_default/azilal-toile-printaniere.jpg'
        )
      "
    />
    <a-avatar v-else shape="square" :size="small"
      ><a-icon slot="icon" type="loading"
    /></a-avatar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "seachTable",
  props: ["text", "showModal"],
  computed: {
    ...mapState(["language"]),
  },
  methods: {
  },
};
</script>
