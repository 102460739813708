<template>
  <div class="table">
    <div v-if="waitServer">
      <a-progress :percent="waitPercent" size="small" />
    </div>
    <div v-else>
      <a-button
        type="primary"
        :disabled="!hasSelected"
        @click="traduction"
        :loading="loading"
        icon="flag"
      >
        Traduire
      </a-button>
      <a-button
        type="primary"
        :disabled="!needSave"
        @click="save_traduction"
        :loading="loading"
        icon="save"
      >
        Sauvegarder
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="{
        position: 'both',
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '100', '250', '500'],
      }"
    >
      <div slot="traduction" slot-scope="text, record">
        <span v-if="record['trad_' + keyName]">
          {{ record["trad_" + keyName] }}
        </span>
        <span v-else>
          {{ text }}
        </span>
      </div>
    </a-table>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      needSave: false,
      loading: false,
      waitServer: false,
      waitMax: 0,
      waitActual: 0,
    };
  },
  props: ["keyName", "type", "data"],
  computed: {
    ...mapState(["user", "userToken"]),
    hasSelected() {
      if (!this.needSave) return this.selectedRowKeys.length > 0;
      else return false;
    },
    columns() {
      return [
        {
          dataIndex: "id",
          key: "id",
          title: "ID",
        },
        {
          dataIndex: "value.language[0]['_']",
          key: "value.language[0]['_']",
          title: this.type + " FR",
        },
        {
          dataIndex: "value.language[1]['_']",
          key: "value.language[1]['_']",
          scopedSlots: { customRender: "traduction" },
          title: this.type + " EN",
        },
      ];
    },
    waitPercent: function() {
      var percent = Math.round((this.waitActual / this.waitMax) * 100);
      return percent;
    },
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.needSave = false;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    traduction() {
      this.loading = true;
      var trad = [];
      this.selectedRows.forEach(function(product) {
        trad.push({
          id: product.id,
          value: product.value.language[0]["_"],
        });
      }, this);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/translate/multiple",
          {
            id: this.user.id,
            sequence: trad,
            lang: "en",
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((items) => {
          items.forEach(function(item) {
            this.selectedRows[_.findIndex(this.selectedRows, { id: item.id })][
              "trad_" + this.keyName
            ] = item.value;
          }, this);
          // this.selectedRows[_.findIndex(this.selectedRows, { 'id': product.id })]["trad_" + this.keyName] = items;
          // this.data[_.findIndex(this.data, { 'id': product.id })]["trad_" + this.keyName] = items;
          this.needSave = true;
        });
      this.loading = false;
    },
    save_traduction() {
      this.loading = true;
      this.$message.loading({
        content: "Sauvegarde ...",
        key: "massEdit",
      });
      this.waitServer = true;
      this.waitMax = this.selectedRows.length;
      this.$store
        .dispatch("editFeatures", {
          action: "editContent",
          features: this.selectedRows,
          columns: [
            {
              id: 0,
              dataIndex: "id",
            },
            {
              id: 0,
              dataIndex: "trad_" + this.keyName,
              selectedMapper: {
                traductable: true,
                value: this.keyName,
                lang: 1,
                underscore: true,
              },
            },
          ],
        })
        .then(([id, socket]) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
          this.sockets.subscribe(socket, (data) => {
            this.waitActual = data.num;
            if (data.result)
              this.$store.commit("CHANGE_Feature", {
                id: data.id,
                data: data.data,
              });
            if (this.waitActual == this.waitMax) {
              this.sockets.unsubscribe(socket);
              this.waitServer = false;
              this.loading = false;
              this.selectedRows = [];
              this.selectedRowKeys = [];
            }
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
  },
};
</script>
