<template>
  <a-descriptions title="Order Detail" :size="middle" bordered>
    <a-descriptions-item :span="4" label="Payment">
      {{ record.payment }}
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Client">
      {{ record.customer.firstname }} {{ record.customer.lastname }} <br />
      {{ record.customer.email }}
      <span v-if="record.current_state['_'] == 3">
        {{ record.customer.id | getCustomerCode }}@tribaliste.link
      </span><br />
      {{ record.address_delivery.phone_mobile }}<br />
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Livraison">
      {{ record.address_delivery.firstname }}
      {{ record.address_delivery.lastname }}<br />
      {{ record.address_delivery.address1 }}
      {{ record.address_delivery.address2 }}<br />
      {{ record.address_delivery.postcode }} {{ record.address_delivery.city
      }}<br />
      {{ record.address_delivery.country.name.language[0]["_"] }}<br />
    </a-descriptions-item>

    <a-descriptions-item :span="4" label="Détail de la commande">
      <div>
        <a-descriptions>
          <a-descriptions-item :span="2" label="Prix payé">
            {{ record.total_paid_real * 1 }}
            <span v-if="record.id_currency['_'] == 1">€</span>
            <span v-if="record.id_currency['_'] == 2">$</span>
          </a-descriptions-item>
          <a-descriptions-item :span="2" label="Frais de port Payé">
            {{ record.total_shipping * 1
            }}<span v-if="record.id_currency['_'] == 1">€</span>
            <span v-if="record.id_currency['_'] == 2">$</span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-for="product in record.products" :key="product.id">
          <a-descriptions-item :span="1" label="Reference">
            {{ product.product_reference }}
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Quantité">
            {{ product.product_quantity * 1 }}
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Prix unitaire">
            {{ product.unit_price_tax_incl * 1 }}
            <span v-if="record.id_currency['_'] == 1">€</span>
            <span v-if="record.id_currency['_'] == 2">$</span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Commande artisan">
      <div v-if="record.pos.length > 0">
        <a-descriptions v-for="po in record.pos" :title="po.ref" :key="po.id">
          <a-descriptions-item :span="2" label="Artisan">
            {{ po.supplier.name }} ({{ po.supplier.code_fournisseur }})
          </a-descriptions-item>
          <a-descriptions-item :span="2" label="Montant">
            {{ po.total_ttc * 1 }}
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Etat">
            <a-tag :color="po.statut | getStatutColor">
              {{ po.statut | getStatut }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Facturé">
            <a-tag :color="'blue'">
              {{ po.billed | getStatutBill }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Action">
            <a-popconfirm
              title="êtes vous sur de vouloir approuver ?"
              ok-text="Oui"
              cancel-text="Non"
              @confirm="approved(record.id, po.id)"
            >
              <a-button v-if="po.statut == 1" type="primary">
                Approuver le PO
              </a-button></a-popconfirm
            ><br />
            <a-popconfirm
              title="êtes vous sur de vouloir désapprouver ?"
              ok-text="Oui"
              cancel-text="Non"
              @confirm="decline(record.id, po.id)"
            >
              <a-button v-if="po.statut == 1" type="danger">
                Refuser le PO
              </a-button></a-popconfirm
            >
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div v-else>No Po Créé</div>
    </a-descriptions-item>
    <a-descriptions-item :span="4" label="Commande Transporteur">
      <div v-if="record.delivery.length > 0">
        <a-descriptions v-for="po in record.delivery" :title="po.ref" :key="po.id">
          <a-descriptions-item :span="2" label="Transporter">
            {{ po.supplier.name }} ({{ po.supplier.code_fournisseur }})
          </a-descriptions-item>
          <a-descriptions-item :span="2" label="Montant">
            {{ po.total_ttc * 1 }}
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Etat">
            <a-tag :color="po.statut | getStatutColor">
              {{ po.statut | getStatut }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item :span="1" label="Facturé">
            <a-tag :color="'blue'">
              {{ po.billed | getStatutBill }}
            </a-tag>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div v-else>No Po Créé</div>
    </a-descriptions-item>
    <a-descriptions-item
      :span="4"
      v-if="record.doli.product"
      label="Facture Produit"
    >
      Montant : {{ record.doli.product.multicurrency_total_ttc * 1 }} <br />
      <a-button
        icon="forward"
        :href="
          'http://factu.host.tribaliste.com/compta/facture/card.php?facid=' +
            record.doli.product.id +
            '&save_lastsearch_values=1'
        "
        target="_blank"
        type=""
        v-if="record.doli.product"
        >Voir la facture DHL</a-button
      >
      <a-button
        icon="download"
        @click="download(record.doli.product.ref, record.id_lang)"
        type=""
        v-if="record.doli.product"
        >Télécharger facture DHL</a-button
      >
    </a-descriptions-item>
    <a-descriptions-item
      :span="4"
      v-if="record.doli.service"
      label="Facture Service"
    >
      Montant : {{ record.doli.service.multicurrency_total_ttc * 1 }}<br />

      <a-button
        icon="forward"
        :href="
          'http://factu.host.tribaliste.com/compta/facture/card.php?facid=' +
            record.doli.service.id +
            '&save_lastsearch_values=1'
        "
        target="_blank"
        type=""
        v-if="record.doli.service"
        >Voir la facture Service</a-button
      >
      <a-button
        icon="download"
        @click="download(record.doli.service.ref, record.id_lang)"
        type=""
        v-if="record.doli.service"
        >Télécharger facture Service</a-button
      >
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "orderDetails",
  props: ["record"],
  components: {},
  computed: {
    ...mapState(["language", "featuresList", "suppliers", "userToken"]),
  },
  filters: {
    getCustomerCode: function(value) {
      return 'c'+String(value).padStart(7, "0");
    },
    getStatutColor: function(value) {
      if (value == 1) return "blue";
      if (value == 2) return "green";
      if (value == 3) return "green";
      if (value == 5) return "purple";
      if (value == 9) return "red";
      return value;
    },
    getStatut: function(value) {
      if (value == 1) return "Attente Artisan";
      if (value == 2) return "Validé par Artisan";
      if (value == 3) return "Validé par Artisan & Commandée";
      if (value == 5) return "Produits reçus";
      if (value == 9) return "Refusé par Artisan";
      return value;
    },
    getStatutBill: function(value) {
      if (value == 0) return "Non Facturé";
      if (value == 1) return "Facturé";
      return value;
    },
  },
  methods: {
    download(id, lang) {
      var langCode = "FR";
      if (lang["_"] == "1") langCode = "FR";
      if (lang["_"] == "2") langCode = "EN";

      console.log(id);
      axios({
        url: process.env.VUE_APP_APIURL + "/order/doli/dl",
        method: "post",
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
        data: {
          ref: id,
          lang: langCode,
        },
        responseType: "blob", // important
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", id + ".pdf");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    approved(id, idPO) {
      this.$message.loading({
        content: "Passage en acceptation artisan (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editPO", { id: id, idPO: idPO, action: "approve" })
        .then((id) => {
          this.$message.success({
            content: "Passage en acceptation artisan réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    decline(id, idPO) {
      this.$message.loading({
        content: "Passage en refus artisan (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editPO", { id: id, idPO: idPO, action: "refuse" })
        .then((id) => {
          this.$message.success({
            content: "Passage en refus artisan réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
  },
};
</script>
