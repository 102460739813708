<template>
  <div id="ordersPage">
    <a-tabs default-active-key="99">
      <a-tab-pane
        key="99"
        :tab="'ALL (' + orders_renta.length + ')'"
        :disabled="orders_renta.length == 0"
      >
        <tableRenta :type="'all'" :orders="orders_renta_sum()" />
      </a-tab-pane>
      <a-tab-pane v-for="(item,key) in month" :key="key" 
        :tab="item"
      >
        <tableRenta :type="'all'" :orders="orders_renta_month(item)" />
      </a-tab-pane>
    </a-tabs>
    
  </div>
</template>

<script>
import tableRenta from "./rentaTable";
import { mapState } from "vuex";
import axios from "axios";
import _ from "lodash";
export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        transmissionCapture: false,
      },
      month : [],
      rentaAll: [],
    };
  },
  components: {
    tableRenta,
  },
  computed: {
    ...mapState(["orders", "language", "loading", "userToken"]),
    orders_renta() {
      let renta = [];
      this.orders.forEach(function(order) {
        if (this.$moment(order.date_add).isAfter("2021-01-01"))
          if ("product" in order.doli) {
            var po_artisan = 0,
              po_delivery = 0,
              po_payment = 0,
              ca_net = 0,
              Prenta = 0,
              soldes = 0,
              promo = 0,
              ca_mad = 0,
              fournDelivery = ["53"];

            ca_mad += (typeof(order.doli.product) != 'undefined') ? parseFloat(order.doli.product.total_ttc) : 0;
            ca_mad += (typeof(order.doli.service) != 'undefined') ? parseFloat(order.doli.service.total_ttc) : 0;

            if (order.pos.length > 0)
              order.pos.forEach(function(po) {
                if (_.includes(fournDelivery, po.fourn_id))
                  po_delivery += parseFloat(po.total_ttc);
                else po_artisan += parseFloat(po.total_ttc);
              });

            if (order.delivery.length > 0)
              order.delivery.forEach(function(po) {
                if (_.includes(fournDelivery, po.fourn_id))
                  po_delivery += parseFloat(po.total_ttc);
                else po_artisan += parseFloat(po.total_ttc);
              });

            if (order.payment == "FPAY")
              po_payment = 0.15 + ca_mad * 0.025 + ca_mad * 0.0015;
            if (order.payment == "PayPal") po_payment = 3.67 + ca_mad * 0.044;

            if (order.products_discounts > 0) soldes = 1;
            if (parseFloat(order.total_discounts) > 0) promo = 1;

            ca_net = ca_mad - po_payment - po_artisan - po_delivery;

            Prenta = (ca_net * 100) / ca_mad;
            if(!_.includes(this.month, this.$moment(order.date_add).format("MM/Y")))
              this.month.push(this.$moment(order.date_add).format("MM/Y"))
            renta.push({
              ref: order.reference,
              date_add: this.$moment(order.date_add).format("DD/MM/Y"),
              ca: parseFloat(order.total_paid_real),
              id_currency: order.id_currency,
              ca_mad: ca_mad,
              po_artisan: po_artisan,
              po_delivery: po_delivery,
              po_payment: po_payment,
              marge_brut: ca_net,
              soldes: soldes,
              soldesPCT: order.products_discounts_pourcentage,
              promo: promo,
              promoSUM: parseFloat(order.total_discounts),
              renta: Prenta,
              all: order,
            });
          }
      }, this);

      return renta;
    },
    orders_renta_sum() {
      var vm = this;
      return function () {
        let sum = _.clone(vm.orders_renta);

        var po_artisan = 0,
          po_delivery = 0,
          po_payment = 0,
          ca_net = 0,
          Prenta = 0,
          ca_mad = 0;

        sum.forEach(function(item) {
          po_artisan += item.po_artisan;
          po_delivery += item.po_delivery;
          po_payment += item.po_payment;
          ca_mad += item.ca_mad;
        });

        ca_net = ca_mad - po_payment - po_artisan - po_delivery;
        Prenta = (ca_net * 100) / ca_mad;


        sum.push({
          ref: 'SOMME',
          ca_mad: ca_mad,
          po_artisan: po_artisan,
          po_delivery: po_delivery,
          po_payment: po_payment,
          marge_brut: ca_net,
          renta: Prenta,
        });
        return sum;
      }
    },
    orders_renta_month() {
      var vm = this;
      return function (month) {
        var sum = []
        var po_artisan = 0,
          po_delivery = 0,
          po_payment = 0,
          ca_net = 0,
          Prenta = 0,
          ca_mad = 0;

        vm.orders_renta.forEach(function(item) {
          if(vm.$moment(item.date_add, "DD/MM/Y").format("MM/Y") == month) {
            po_artisan += item.po_artisan;
            po_delivery += item.po_delivery;
            po_payment += item.po_payment;
            ca_mad += item.ca_mad;
            sum.push(item)
          }
        });

        ca_net = ca_mad - po_payment - po_artisan - po_delivery;
        Prenta = (ca_net * 100) / ca_mad;


        sum.push({
          ref: 'SOMME',
          ca_mad: ca_mad,
          po_artisan: po_artisan,
          po_delivery: po_delivery,
          po_payment: po_payment,
          marge_brut: ca_net,
          renta: Prenta,
        });
        return sum;
      }
    },
  },
  methods: {
    transmissionCapture() {
      if (!this.loading.transmissionCapture) {
        this.loading.transmissionCapture = true;
        this.$message.loading({
          content: "Transmission des captures",
          key: "transmission_capture",
        });
        console.log(this.orders_step2);
        axios
          .post(
            process.env.VUE_APP_APIURL + "/orders/capture",
            {
              data: this.orders_step2,
            },
            {
              headers: {
                Authorization: `Bearer ${this.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then(() => {
            this.orders_step2.forEach(function(order) {
              var action = "acheminement";
              if (order.current_state["_"] == "28") action = "refund";
              this.$store
                .dispatch("editOrder", { id: order.id, action: action })
                .then(() => {
                  this.$message.success({
                    content: "Capture réussi",
                    key: "transmission_capture",
                  });
                })
                .catch(([error, id]) => {
                  console.log(error);
                  this.$message.error({
                    content: "Echec  " + error,
                    key: id,
                  });
                });
            }, this);
            this.loading.transmissionCapture = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading.transmissionCapture = false;
            this.$message.error({
              content: "Echec  " + error,
              key: "transmission_capture",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
#ordersPage {
  width: 100%;
}
</style>
