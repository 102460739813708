<template>
  <div class="actions">
    <a-tooltip v-if="capturable" placement="top" title="Pret à capturer !">
      <a-button
        type="primary"
        @click="valide_artisan(data.id)"
        icon="check"
        :loading="loadings.product"
      />
    </a-tooltip>
    <a-tooltip
      v-if="validableArtisan"
      placement="top"
      title="Créer le PO et prévenir les artisans"
    >
      <a-button
        type="primary"
        @click="createPO(data.id)"
        icon="wechat"
        :loading="loadings.product"
      />
    </a-tooltip>

    <a-tooltip v-if="facturable" placement="top" title="Créer la facture">
      <a-button
        type="primary"
        @click="create(data.id)"
        icon="calculator"
        :loading="loadings.product"
      />
    </a-tooltip>
    <a-tooltip
      v-if="receptionnable"
      placement="top"
      title="Réceptionner la marchandise"
    >
      <a-button
        type="primary"
        @click="receptionner(data.reference, data)"
        icon="home"
        :loading="loadings.product"
      />
    </a-tooltip>
    <a-tooltip v-if="preparable" placement="top" title="Passer en preparation">
      <a-button
        type="primary"
        @click="prepared(data.id, data)"
        icon="code-sandbox"
        :loading="loadings.product"
      />
    </a-tooltip>

    <a-tooltip v-if="collectable" placement="top" title="Passer en collecte">
      <a-button
        type="primary"
        @click="collected(data.id, data.reference)"
        icon="scan"
        :loading="loadings.product"
      />
    </a-tooltip>

    <a-tooltip v-if="livrable" placement="top" title="Passer en livraison">
      <a-button
        type="primary"
        @click="ondelivery(data.id)"
        icon="audit"
        :loading="loadings.product"
      />
    </a-tooltip>

    <a-tooltip v-if="isDelivered" placement="top" title="Passer en livré">
      <a-button
        type="primary"
        @click="delivered(data.id, data.reference)"
        icon="smile"
        :loading="loadings.product"
      />
    </a-tooltip>

    <a-tooltip placement="top" title="Voir la commande (BO)">
      <a-button
        type=""
        :href="
          'https://www.tribaliste.com/admin3537gmxyz/index.php?controller=AdminOrders&vieworder&id_order=' +
            data.id
        "
        target="_blank"
        icon="eye"
      />
    </a-tooltip>
    <a-tooltip placement="top" title="Alerter un admin (BO)">
      <a-button
        type="danger"
        @click="alertAdmin(data.id, data.customer.id)"
        target="_blank"
        icon="alert"
      />
    </a-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  name: "actions",
  data() {
    return {
      commentAdmin: "",
      delivery: {
        carrier : '',
        price: 0,
        tracking: 0,
        weight: 0,
      },
    };
  },
  props: ["data"],
  computed: {
    ...mapState(["language", "loadings", "userToken"]),
    validableArtisan: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "2") {
        if (
          this.data.pos.length == 0 &&
          this.data.supplier.length !=
            _.filter(this.data.supplier, function(value) {
              return value == "4";
            }).length
        )
          rPostion = true;
      }
      return rPostion;
    },
    capturable: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "2") {
        if (this.data.pos.length > 0) {
          if (
            this.data.pos.length ==
            _.filter(this.data.pos, { statut: "3" }).length
          )
            rPostion = true;
        } else {
          if (
            this.data.supplier.length ==
            _.filter(this.data.supplier, function(value) {
              return value == "4";
            }).length
          ) {
            rPostion = true;
          }
        }
      }
      return rPostion;
    },
    facturable: function() {
      var dateOrder = new Date(this.data.date_add);
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "29") {
        if (
          dateOrder > new Date("2021-01-01") &&
          typeof this.data.doli.product == "undefined"
        ) {
          rPostion = true;
        }
      }
      return rPostion;
    },
    receptionnable: function() {
      var dateOrder = new Date(this.data.date_add);
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "29") {
        if (
          dateOrder > new Date("2021-01-01") &&
          typeof this.data.doli.product != "undefined"
        ) {
          rPostion = true;
        } else if (dateOrder < new Date("2021-01-01")) {
          rPostion = true;
        }
      }
      return rPostion;
    },
    preparable: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "30") {
        rPostion = true;
      }
      return rPostion;
    },
    collectable: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "3") {
        rPostion = true;
      }
      return rPostion;
    },
    livrable: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "31") {
        rPostion = true;
      }
      return rPostion;
    },
    isDelivered: function() {
      var cState = this.data.current_state;
      var rPostion = false;
      if (cState["_"] == "4") {
        rPostion = true;
      }
      return rPostion;
    },
  },
  methods: {
    refund(id) {
      this.$message.loading({
        content: "Remboursement (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editOrder", { id: id, action: "refund" })
        .then((id) => {
          this.$message.success({ content: "Remboursement réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec " + error,
            key: id,
          });
        });
    },
    cancel(id) {
      this.$message.loading({ content: "Annulation (" + id + ")...", key: id });
      this.$store
        .dispatch("editOrder", { id: id, action: "cancel" })
        .then((id) => {
          this.$message.success({ content: "Annulation réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec de l'Annulation " + error,
            key: id,
          });
        });
    },
    ondelivery(id) {
      this.$message.loading({
        content: "Passage en livraison (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editOrder", { id: id, action: "ondelivery" })
        .then((id) => {
          this.$message.success({
            content: "Passage en livraison réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec " + error,
            key: id,
          });
        });
    },
    delivered(id, reference) {
      this.$message.loading({
        content: "Passage en livraison (" + id + ")...",
        key: id,
      });
      axios
        .put(
          process.env.VUE_APP_APIURL + "/order/doli/POreceived",
          {
            ref: reference,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.$store
            .dispatch("editOrder", { id: id, action: "delivered" })
            .then((id) => {
              this.$message.success({
                content: "Passage en livraison réussi",
                key: id,
              });
            })
            .catch(([error, id]) => {
              this.$message.error({
                content: "Echec " + error,
                key: id,
              });
            });
        });
    },
    collected_step2(id, reference, delivery) {
      console.log(id);
      console.log(delivery);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/order/doli/createDelivery",
          {
            delivery: {
              reference: reference,
              tracking: delivery.tracking,
              weight: delivery.weight,
              price: delivery.price,
              carrier: delivery.carrier,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.$message.loading({
            content: "Passage en collecte (" + id + ")...",
            key: id,
          });
          this.$store
            .dispatch("editOrder", { id: id, action: "collecte" })
            .then((id) => {
              this.$message.success({
                content: "Passage en collecte réussi",
                key: id,
              });
            })
            .catch(([error, id]) => {
              this.$message.error({
                content: "Echec " + error,
                key: id,
              });
            });
        });
    },
    collected(id, reference) {
      console.log(id);
      const h = this.$createElement;
      var thos = this;
      this.$confirm({
        icon: "alert",
        title: "Merci de renseigner les informations de livraison",
        content: [
          h(
            "a-select",
            {
              attrs: {
                style : 'width: 200px;',
                placeholder :"Select a Carrier"
              },
              on: {
                change: function(value) {
                  thos.delivery.carrier = value;
                },
              },
            },
            [
              h("a-select-option", {
                attrs : {
                  value : "53"
                },
              },
                ['DHL']
              ),
              h("a-select-option", {
                attrs : {
                  value : "134"
                },},
                ['Oubratra']
              ),
              h("a-select-option", {
                attrs : {
                  value : "146"
                },},
                ['Chronopost Maroc']
              )
            ]
          ),
          h(
            "a-input",
            {
              attrs: {
                placeholder: "Poids chargeable",
              },
              on: {
                change: function(value) {
                  thos.delivery.weight = value.path[0]["value"];
                },
              },
            },
            []
          ),
          h(
            "a-input",
            {
              attrs: {
                placeholder: "Prix estimé",
              },
              on: {
                change: function(value) {
                  thos.delivery.price = value.path[0]["value"];
                },
              },
            },
            []
          ),
          h(
            "a-input",
            {
              attrs: {
                placeholder: "Numéro de tracking",
              },
              on: {
                change: function(value) {
                  thos.delivery.tracking = value.path[0]["value"];
                },
              },
            },
            []
          ),
        ],
        onOk() {
          thos.collected_step2(id, reference, thos.delivery);
        },
        class: "test",
      });
    },
    prepared(id, data) {
      this.$message.loading({
        content: "Passage en preparation (" + id + ")...",
        key: id,
      });
      axios
        .post(
          process.env.VUE_APP_APIURL + "/order/mail",
          {
            code_client: data.customer.id,
            email_client: data.customer.email,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          this.$store
            .dispatch("editOrder", { id: id, action: "prepared" })
            .then((id) => {
              this.$message.success({
                content: "Passage en preparation réussi",
                key: id,
              });
            })
            .catch(([error, id]) => {
              this.$message.error({
                content: "Echec  " + error,
                key: id,
              });
            });
        });
    },
    valide_artisan(id) {
      this.$message.loading({
        content: "Passage en validation artisan (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editOrder", { id: id, action: "validArtisan" })
        .then((id) => {
          this.$message.success({
            content: "Passage en validation artisan réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    refuse_artisan(id) {
      this.$message.loading({
        content: "Passage en refus artisan (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editOrder", { id: id, action: "refuseArtisan" })
        .then((id) => {
          this.$message.success({
            content: "Passage en refus artisan réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    create(id) {
      this.$message.loading({
        content: "Création facture (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editAndCreateOrder", { id: id, action: "create" })
        .then((id) => {
          this.$message.success({
            content: "Création facture réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          console.log(error);
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    createPO(id) {
      this.$message.loading({
        content: "Création PO (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editAndCreatePO", { id: id, action: "create" })
        .then((id) => {
          this.$message.success({
            content: "Création po réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          console.log(error);
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    receptionner(id, data) {
      this.$message.loading({
        content: "Passage en Reception (" + id + ")...",
        key: data.id,
      });
      axios
        .put(
          process.env.VUE_APP_APIURL + "/order/doli/POreceived",
          {
            ref: id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((item) => {
          var filterSupplier = _.map(data.supplier, function(o) {
            if (o == "4") return o;
          });
          filterSupplier = _.without(filterSupplier, undefined);
          if (
            filterSupplier.length != data.supplier.length &&
            item == "OK but no PO for this Order !"
          ) {
            var thos = this;
            this.$confirm({
              title: "Nous n'avons pas trouvé de PO, confirmer la réception ?",
              content:
                "rapprochez vous de l'administrateur si vous avez un doute :D",
              onOk() {
                thos.receptionner_step2(data.id);
              },
            });
          } else {
            this.receptionner_step2(data.id);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error({
            content: "Echec  " + error,
            key: data.id,
          });
        });
    },
    receptionner_step2(id) {
      console.log(id);
      this.$store
        .dispatch("editOrder", { id: id, action: "reception" })
        .then((id) => {
          this.$message.success({
            content: "Passage en Reception réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    alertAdmin(id, idClient) {
      console.log(idClient);
      const h = this.$createElement;
      var thos = this;
      this.$confirm({
        icon: "alert",
        title:
          "Souhaitez vous alerter un admin ? Attention la commande sera bloquée",
        content: h(
          "a-textarea",
          {
            attrs: {
              placeholder: "Inserer votre commentaire",
              autoSize: "{ minRows: 3, maxRows: 5 }",
            },
            on: {
              change: function(value) {
                thos.commentAdmin = value.path[0]["value"];
              },
            },
          },
          []
        ),
        onOk() {
          thos.alertAdmin_step2(id, idClient, thos.commentAdmin);
        },
        class: "test",
      });
    },
    alertAdmin_step2(id, idClient, comment) {
      var thos = this;
      this.$message.loading({
        content: "Insertion du commentaire (" + id + ")...",
        key: id,
      });
      axios
        .put(
          process.env.VUE_APP_APIURL + "/order/makeComment",
          {
            ref: idClient,
            comment: "Commande " + id + " : " + comment,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then(() => {
          thos.alertAdmin_step3(id);
        })
        .catch((error) => {
          console.log(error);
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
    alertAdmin_step3(id) {
      console.log(id);
      this.$store
        .dispatch("editOrder", { id: id, action: "alertAdmin" })
        .then((id) => {
          this.$message.success({
            content: "Passage en AlertAdmin réussi",
            key: id,
          });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echec  " + error,
            key: id,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.actions {
  .ant-btn {
    margin: 0 1.5%;
  }
}</style
>>
