var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[(_vm.waitServer)?_c('div',[_c('a-progress',{attrs:{"percent":_vm.waitPercent,"size":"small"}})],1):_c('div',[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.hasSelected,"loading":_vm.loading,"icon":"flag"},on:{"click":_vm.traduction}},[_vm._v(" Traduire ")]),_c('a-button',{attrs:{"type":"primary","disabled":!_vm.needSave,"loading":_vm.loading,"icon":"save"},on:{"click":_vm.save_traduction}},[_vm._v(" Sauvegarder ")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"pagination":{
      position: 'both',
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '100', '250', '500'],
    }},scopedSlots:_vm._u([{key:"traduction",fn:function(text, record){return _c('div',{},[(record['trad_' + _vm.keyName])?_c('span',[_vm._v(" "+_vm._s(record["trad_" + _vm.keyName])+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }