<template>
  <div class="productsTable">
    <div v-if="type == 'expedie'">
      <a-button v-if="trackingLoad" slot="extra" icon="loading"
        >Get Tracking status</a-button
      >
      <a-button v-else slot="extra" icon="reload" @click="getTrackingStatus"
        >Get Tracking status</a-button
      >
    </div>
    <a-table
      class="orders"
      :columns="columns"
      :data-source="orders"
      rowKey="id"
      style="{width='100%'; }'"
      :pagination="{ position: 'both', 'show-size-changer': 'true' }"
    >
      <filterDropdown
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        :setSelectedKeys="setSelectedKeys"
        :selectedKeys="selectedKeys"
        :confirm="confirm"
        :clearFilters="clearFilters"
        :column="column"
      />
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <clientCell slot="client" slot-scope="record" :data="record" />
      <priceCell
        slot="price"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <stateCell
        slot="state"
        slot-scope="text, data"
        :text="text"
        :data="data"
      />
      <refCell slot="ref" slot-scope="text, data" :text="text" :data="data" />
      <actionsCell slot="action" slot-scope="data" :data="data" />
      <orderDetails
        slot="expandedRowRender"
        slot-scope="record"
        :record="record"
        style="margin: 0"
      />
    </a-table>
    <a-modal v-model="imgModal" width="50%" :footer="null">
      <img :src="imgUrl" width="100%" />
    </a-modal>
  </div>
</template>

<script>
import priceCell from "./table/price";
import clientCell from "./table/client";
import stateCell from "./table/state";
import refCell from "./table/ref";
import orderDetails from "./table/orderDetails";
import actionsCell from "./table/actions";

//import _ from "lodash";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    title: "ID",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.id
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
    sorter: (a, b) => a.id - b.id,
    sortDirections: ["descend", "ascend"],
  },
  {
    dataIndex: "reference",
    key: "reference",
    title: "REF",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.reference
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
  {
    dataIndex: "date_add",
    key: "date_add",
    title: "Date",
    sorter: (a, b) => a.date_add - b.date_add,
  },
  {
    dataIndex: "total_paid_real",
    key: "total_paid_real",
    title: "Prix",
    sorter: (a, b) => a.total_paid_real - b.total_paid_real,
    scopedSlots: { customRender: "price" },
  },
  {
    key: "customerName",
    title: "Client",
    scopedSlots: { customRender: "client" },
  },
  {
    dataIndex: "state",
    key: "state",
    title: "État",
    scopedSlots: { customRender: "state" },
  },
  {
    dataIndex: "productRef",
    key: "productRef",
    title: "Refs P",
    scopedSlots: { customRender: "ref" },
  },
  {
    dataIndex: "supplier",
    key: "supplier",
    title: "Artisan",
    scopedSlots: { customRender: "ref" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "dashboard",
  props: ["type", "orders"],
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      trackingLoad: false,
    };
  },
  components: {
    priceCell,
    clientCell,
    actionsCell,
    refCell,
    stateCell,
    orderDetails,
  },
  computed: {
    ...mapState(["language", "loading"]),
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
    getTrackingStatus() {
      var tracking = [];
      this.trackingLoad = true;
      this.orders.forEach(function(order) {
        if (order.delivery.length > 0)
          order.delivery.forEach(function(del) {
            if ("array_options" in del)
              if ("options_track" in del.array_options)
                tracking.push({
                  id: order.id,
                  carrier : del.socid,
                  tracking: del.array_options.options_track,
                });
          });
      });
      console.log(tracking);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/tracking/trackings",
          {
            tracking: tracking,
          },
          {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((items) => {
          this.$store
            .dispatch("editOrderTrackingStatus", items)
            .then(() => {
              this.$message.success({
                content: "DHL Tracking OK",
                key: "transmission_capture",
              });
              this.trackingLoad = false;
            })
            .catch(([error, id]) => {
              console.log(error);
              this.$message.error({
                content: "Echec  " + error,
                key: id,
              });
              this.trackingLoad = false;
            });
        });
    },
  },
};
</script>

<style lang="scss">
.productsTable {
  width: 90%;
}
</style>
