<template>
  <div id="traductionPage">
    <a-tabs default-active-key="1">
      <a-tab-pane
        key="1"
        :tab="'Couleurs (' + needTrad_couleurs.length + ')'"
        :disabled="needTrad_couleurs.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_couleurs"
        />
      </a-tab-pane>
      <a-tab-pane
        key="2"
        :tab="'Tissage (' + needTrad_tissage.length + ')'"
        :disabled="needTrad_tissage.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_tissage"
        />
      </a-tab-pane>
      <a-tab-pane
        key="3"
        :tab="'Composition (' + needTrad_composition.length + ')'"
        :disabled="needTrad_composition.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_composition"
        />
      </a-tab-pane>
      <a-tab-pane
        key="4"
        :tab="'Teinture (' + needTrad_teinture.length + ')'"
        :disabled="needTrad_teinture.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_teinture"
        />
      </a-tab-pane>
      <a-tab-pane
        key="5"
        :tab="'Fabrication (' + needTrad_fabrication.length + ')'"
        :disabled="needTrad_fabrication.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_fabrication"
        />
      </a-tab-pane>
      <a-tab-pane
        key="6"
        :tab="'Famille (' + needTrad_famille.length + ')'"
        :disabled="needTrad_famille.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_famille"
        />
      </a-tab-pane>
      <a-tab-pane
        key="7"
        :tab="'Design (' + needTrad_design.length + ')'"
        :disabled="needTrad_design.length == 0"
      >
        <tableTrad
          :keyName="'Value'"
          :type="'Value'"
          :data="needTrad_design"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import tableTrad from "./trad/table";

import { mapState } from "vuex";

export default {
  name: "dashboard",
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      imgModal: false,
      loading: {
        title: true,
        description: true,
        meta_title: true,
        meta_description: true,
      },
    };
  },
  components: {
    tableTrad,
  },
  computed: {
    ...mapState(["featuresList", "language", "loading"]),
    needTrad_couleurs() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "24") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_tissage() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "14") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_composition() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "15") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_teinture() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "16") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_fabrication() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "17") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_famille() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "18") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
    needTrad_design() {
      let needTrad = [];
      this.featuresList.forEach(function(feature) {
        if (feature.id_feature["_"] == "25") {
          if (
            feature.value.language[0]["_"] == feature.value.language[1]["_"] ||
            feature.value.language[1]["_"] == ""
          )
            needTrad.push(feature);
        }
      });
      return needTrad;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#traductionPage {
  width: 90%;
}
</style>
