<template>
  <div class="editable-cell">
    <a-tag :key="text.name.language[0]['_']" :color="getColor()"
      >{{ text.name.language[0]["_"] }}
    </a-tag>
    <div v-if="text.name.language[0]['_'] == 'Expédié'">
   
      <a-button
        type="primary"
        shape="circle"
        class="trckBt"
        icon="search"
        size="small"
        @click="get17T(data.delivery[0].array_options.options_track, data.delivery[0].socid)"
      />
    </div>
    <div v-if="data.deliveryStatus.info != 'unknown'">
      

      <div v-if="data.deliveryStatus.carrier == 'DHL'">
        <a-button
          size="small"
          type="primary"
          icon="forward"
          v-if="data.deliveryStatus.info != 'unknown'"
          :href="
            'https://mydhl.express.dhl/ma/fr/tracking.html#/results?id=' +
              data.deliveryStatus.tracking
          "
          target="_blank"
        >
          {{ data.deliveryStatus.info }}
          <span v-if="data.deliveryStatus.info != 'transit'">
            - prévu :
            {{
              data.deliveryStatus.detail.shipments[0].estimatedTimeOfDelivery ||
                "?"
            }}
          </span>
        </a-button>
      </div>
      <div v-if="data.deliveryStatus.carrier == 'OUBRATRA'">
        <a-button
          size="small"
          type="primary"
          icon="forward"
          v-if="data.deliveryStatus.info != 'unknown'"
          :href="'https://www.oubratra.ma/'"
          target="_blank"
        >
          {{ data.deliveryStatus.info }}
        </a-button>
      </div>

      <div v-if="data.deliveryStatus.carrier == 'CHRONOPOST'">
        <a-button
          size="small"
          type="primary"
          icon="forward"
          v-if="data.deliveryStatus.info != 'unknown'"
          :href="
            'https://www.chronopost.ma/suivez-votre-envoi?SendingNumber=' +
              data.deliveryStatus.tracking
          "
          target="_blank"
        >
          {{ data.deliveryStatus.info }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "price",
  props: ["text", "data"],
  computed: {
    ...mapState(["language", "loadings"]),
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    getColor() {
      switch (this.text.name.language[0]["_"]) {
        case "Paiement accepté":
          return "#32cd32";
        case "En cours de préparation":
          return "#FF8C00";
        case "Remboursé":
        case "Annulé":
          return "#ec2e15";
        case "Livré":
          return "#108510";
        case "Erreur de paiement":
          return "#8f0621";
      }
      return "#2db7f5";
    },

    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.editPrice(this.data.id, this.value);
    },
    edit() {
      this.editable = true;
    },
    editPrice(id, value) {
      this.$message.loading({
        content: "Edition prix (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editProduct", { id: id, action: "price", value: value })
        .then((id) => {
          this.$message.success({ content: "Modification réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la modification " + error,
            key: id,
          });
        });
    },
    get17T(tracking, carrier) {
      const h = this.$createElement;
      this.$info({
        title: "Tracking " + tracking,
        content: h(
          "div",
          {
            attrs: {
              id: "cont_" + tracking,
            },
          },
          []
        ),
        onOk() {},
      });
      var cId = '';
      switch(carrier) {
        case '53':
          cId = '100001';
          break;
        case '146':
          cId = '100137';
        
      }
      window.setTimeout(function() {
        window.YQV5.trackSingle({
          YQ_ContainerId: "cont_" + tracking,
          YQ_Width: 400,
          YQ_Height: 560,
          YQ_Fc: cId,
          YQ_Lang: "fr",
          YQ_Num: tracking,
        });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.trckBt {
  z-index: 99;
  iframe {
    margin-left: 35px;
  }
}

.yq-panel-logo > button {
  display: block !important;
}
</style>
