import Vue from "vue";
import Vuex from "vuex";
import VueSocketIO from 'vue-socket.io'
import excel from 'vue-excel-export'
import App from "./App.vue";
import HighlightJs from "./directives/highlight";
import Antd from "ant-design-vue";
import LoadScript from "vue-plugin-load-script";
import "ant-design-vue/dist/antd.css";
Vue.use(Vuex);
Vue.use(excel);
Vue.use(Antd);
Vue.use(LoadScript);
Vue.directive("highlightjs", HighlightJs);
Vue.use(require('vue-moment'));
import { Auth0Plugin } from "./auth";
import store from "./store/store";
Vue.config.productionTip = false;
Vue.config.silent = true;
import router from "./router";
Vue.loadScript("https://www.17track.net/externalcall.js")

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_auth0domain,
  clientId: process.env.VUE_APP_auth0client,
  audience : process.env.VUE_APP_auth0audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(new VueSocketIO({
  debug: false,
  connection: process.env.VUE_APP_SOCKETURL,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }}))

// Vue.use(VueGtm, {
//   id: ['GTM-K4ZD27D'], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.config.productionTip = false;
