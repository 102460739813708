<template>
  <div class="actions">
    <a-button
      v-if="data.active == 1"
      type=""
      icon="stop"
      @click="desactive(data.id)"
      :loading="loadings.product"
    />
    <a-button
      v-if="data.active == 0"
      type=""
      @click="active(data.id)"
      icon="check"
      :loading="loadings.product"
    />
    <a-button
      v-if="data.active == 1"
      type=""
      :href="'https://www.tribaliste.com/cat/'+data.id+'-reste-du-nom.html'"
      target="_blank"
      icon="eye"
    />
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "actions",
  props: ["data"],
  computed: {
    ...mapState(["language", "loadings"]),
  },
  methods: {
    active(id) {
      this.$message.loading({ content: "Activation (" + id + ")...", key : id});
      this.$store
        .dispatch("editProduct", {id : id, action : "active"})
        .then((id) => {
          this.$message.success({ content: "Activation réussi", key : id });
        })
        .catch(([error, id]) => {
          this.$message.error({content: "Echet de l'activation " + error, key : id});
        });
    },
    desactive(id) {
      this.$message.loading({ content: "Activation (" + id + ")...", key : id});
      this.$store
        .dispatch("editProduct", {id : id, action : "desactive"})
        .then((id) => {
          this.$message.success({ content: "Activation réussi", key : id });
        })
        .catch(([error, id]) => {
          this.$message.error({content: "Echet de l'activation " + error, key : id});
        });
    },
  },
};
</script>

<style lang="scss">
.actions {
  a.ant-btn {
    margin: 0 5%;
  }
}
</style>>