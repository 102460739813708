import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";
import _ from "lodash";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: false,
    userToken: false,
    userAuth: false,
    products: [],
    productSoldes: [],
    soldesRules: [],
    pos: [],
    billednotpayed : [],
    productStructure: {},
    supplierStructure: {},
    productsWait: [],
    orders: false,
    stocks: false,
    featuresList: false,
    categoriesList: false,
    suppliers: [],
    loading: true,
    updateFromServer: {
      products: {
        active: false,
      },
      stop: 0,
    },
    waStatus : {
      api : 'unknown',
      server : 'unknown',
      lastok : {
        api : 'Unknown',
        server : 'Unknown'
      },
      lastcheck : 'none'
    },
    language: 0,
    languageDetail: [
      { key: 0, name: "FR", code: "fr" },
      { key: 1, name: "EN", code: "en" },
    ],
    loadings: {
      products: false,
      productSoldes: false,
      soldesRules: false,
      orders: false,
      product: false,
      stocks: false,
      features: false,
      categories: false,
      suppliers: false,
      productStructure: false,
      supplierStructure: false,
      pos: false,
      billednotpayed : false,
    },
    protected: {
      products: {
        protected: true,
      },
    },
  },

  getters: {
    user: (state) => {
      return state.user;
    },
    userToken: (state) => {
      return state.userToken;
    },
    orders: (state) => {
      return state.orders;
    },
    userAuth: (state) => {
      return state.userAuth;
    },
    products: (state) => {
      return state.products;
    },
    productStructure: (state) => {
      return state.productStructure;
    },
    supplierStructure: (state) => {
      return state.supplierStructure;
    },
    featuresList: (state) => {
      return state.featuresList;
    },
    categoriesList: (state) => {
      return state.categoriesList;
    },
    suppliers: (state) => {
      return state.suppliers;
    },
    language: (state) => {
      return state.language;
    },
    languageDetail: (state) => {
      return state.languageDetail;
    },
    loading: (state) => {
      return state.loading;
    },
    loadings: (state) => {
      return state.loadings;
    },
    protected: (state) => {
      return state.protected;
    },
    updateFromServer: (state) => {
      return state.updateFromServer;
    },
    waStatus : (state) => {
      return state.waStatus;
    }
  },

  mutations: {
    SET_Items(state, items) {
      state.items = items;
    },
    SET_Products(state, products) {
      state.products = products;
    },
    SET_ProductStructure(state, productStructure) {
      state.productStructure = productStructure;
      state.productStructure.check = {
        all: false,
        uniq: true,
        supplier: true,
        info: true,
        categories: true,
        features: true,
        featuresResfused: [],
        photos: "wait",
        traduction: "wait",
        stock: true,
      };
      state.productStructure.step1 = [];
      state.productStructure.step2 = [];
      state.productStructure.step3 = [];
      state.productStructure.step4 = [];
      state.productStructure.step5 = [];

      state.productStructure.id_shop_default["_"] = 1;
      state.productStructure.type["_"] = "simple";
      state.productStructure.show_price["_"] = 1;
      state.productStructure.is_virtual["_"] = 0;
      state.productStructure.on_sale["_"] = 0;
      state.productStructure.ecotax["_"] = 0;
      state.productStructure.active["_"] = 1;
      state.productStructure.available_for_order["_"] = 1;
      state.productStructure.id_tax_rules_group["_"] = 71;
    },
    SET_SupplierStructure(state, supplierStructure) {
      state.supplierStructure = supplierStructure;
      state.supplierStructure.id_product_attribute = 0;

      delete state.supplierStructure.id_currency;
      delete state.supplierStructure.product_supplier_reference;
      delete state.supplierStructure.product_supplier_price_te;
    },
    SET_Orders(state, orders) {
      state.orders = orders;
    },
    SET_ProductSoldes(state, soldes) {
      state.productSoldes = soldes;
    },
    SET_SoldesRules(state, rules) {
      state.soldesRules = rules;
    },
    ADD_Soldes(state, obj) {
      state.productSoldes.push(obj.data);
    },
    CHANGE_Product(state, obj) {
      var index = _.findIndex(state.products, { id: obj.id });
      state.products[index] = obj.data;
    },
    CHANGE_Feature(state, obj) {
      var index = _.findIndex(state.featuresList, { id: obj.id });
      state.featuresList[index] = obj.data;
    },
    CHANGE_Order(state, obj) {
      var index = _.findIndex(state.orders, { id: obj.id });
      state.orders[index] = obj.data;
    },
    SET_Stocks(state, stocks) {
      state.stocks = stocks;
    },
    SET_FeaturesList(state, features) {
      state.featuresList = features;
    },
    SET_Pos(state, pos) {
      state.pos = pos;
    },
    SET_billednotpayed(state, billednotpayed) {
      state.billednotpayed = billednotpayed;
    },
    DELETE_Feature(state, obj) {
      var index = _.findIndex(state.featuresList, { id: obj.id });
      console.log(index);
      state.featuresList.splice(index, 1);
    },
    DELETE_ProductSoldes(state, obj) {
      var index = _.findIndex(state.productSoldes, { id: obj.id });
      console.log(index);
      state.productSoldes.splice(index, 1);
    },
    SET_CategoriesList(state, categories) {
      state.categoriesList = categories;
    },
    SET_User(state, user) {
      state.user = user;
    },

    SET_Suppliers(state, suppliers) {
      state.suppliers = suppliers;
    },
    SET_UserAuth(state, userAuth) {
      state.userAuth = userAuth;
    },
    SET_UserToken(state, userToken) {
      state.userToken = userToken;
    },
    loading(state, isLoading) {
      state.loading = isLoading;
    },
    loadings(state, obj) {
      state.loadings[obj.type] = obj.isLoading;
    },
    clear(state, obj) {
      state[obj.type] = [];
    },
    protected(state, obj) {
      state.protected[obj.type] = {
        protected: obj.isProtected,
        socketId: obj.socketId,
        max: obj.max,
      };
    },
    receiveUpdateFromServer(state, obj) {
      state.updateFromServer[obj.from] = {
        active: true,
        socketId: obj.socketId,
        max: obj.max,
      };
    },
    receiveUpdateFromServerInactive(state, obj) {
      state.updateFromServer[obj.from] = {
        active: false,
        socketId: 0,
        max: 0,
      };
      if (obj.stop) {
        state.updateFromServer.stop = obj.stop;
      }
    },
    acceptUpdateFromServer(state, from) {
      switch (from) {
        case "products":
          state.products = _.clone(state.productsWait);
          this.commit("loadings", { type: "products", isLoading: true });
          this.commit("protected", {
            type: "products",
            isProtected: false,
            socketId: state.updateFromServer.products.socketId,
            max: state.updateFromServer.products.max,
          });

          this.commit("receiveUpdateFromServerInactive", { from: "products" });
          break;
      }
    },
    SOCKET_loadproducts(state, obj) {
      if (!state.protected.products.protected) {
        this.commit("receiveUpdateFromServerInactive", { from: "products" });
        if (state.protected.products.socketId == obj.id) {
          state.products = state.products.concat(obj.data);
          console.log(state.products.length, obj.max);
          if (state.products.length + 1 >= obj.max) {
            state.loadings["products"] = false;
            this.commit("protected", {
              type: "products",
              isProtected: true,
              socketId: 0,
              max: 0,
            });
          }
        }
      } else {
        if (obj.num - obj.data.length == 1) {
          this.commit("clear", { type: "productsWait" });
        }
        this.commit("receiveUpdateFromServer", {
          from: "products",
          socketId: obj.id,
          max: obj.max,
        });
        state.productsWait = state.productsWait.concat(obj.data);
      }
    },
    SOCKET_WASTATUS(state, obj) {
      state.waStatus = obj;
    },
  },
  
  actions: {
    receiveUpdateFromServerInactive({ commit }, from) {
      commit("receiveUpdateFromServerInactive", from);
    },
    acceptUpdateFromServer({ commit }, from) {
      commit("acceptUpdateFromServer", from);
    },
    loadUser({ commit, dispatch }, id, next = true) {
      commit("loading", true);
      axios
        .post(
          process.env.VUE_APP_APIURL + "/",
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.userToken}`,
            },
          }
        )
        .then((response) => response.data)
        .then((items) => {
          commit("SET_User", items.user);
          commit("loading", false);
          if (next) dispatch("loadOrders");
        });
    },
    flushCache({ commit, dispatch }) {
      commit("loading", true);
      axios
        .get(process.env.VUE_APP_APIURL + "/cache/removeCache", {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
        })
        .then((response) => response.data)
        .then(() => {
          commit("loading", false);
          dispatch("loadOrders");
        });
    },
    loadProducts({ commit, dispatch }, obj = { cache: true, next: true }) {
      commit("loadings", { type: "products", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/products?cache=" + obj.cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("clear", { type: "products" });
          commit("clear", { type: "productsWait" });
          commit("protected", {
            type: "products",
            isProtected: false,
            socketId: items.id,
            max: items.num,
          });
          dispatch("loadFeatures");
          dispatch("loadCategories");
          dispatch("loadProductStructure");
          dispatch("loadSupplierStructure");
          dispatch("loadProductSoldes");
          dispatch("loadSoldesRules");
          dispatch("loadSupplierStructure");
          if (obj.next) {
            dispatch("loadPos");
            dispatch("loadBillednotpayed");
            dispatch("loadSuppliers");
          }
        });
    },
    loadFeatures({ commit }, cache = true) {
      commit("loadings", { type: "features", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/products/features?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_FeaturesList", items);
          commit("loadings", { type: "features", isLoading: false });
        });
    },
    loadPos({ commit }, cache = true) {
      commit("loadings", { type: "pos", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/pos?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_Pos", items);
          commit("loadings", { type: "pos", isLoading: false });
        });
    },
    loadBillednotpayed({ commit }, cache = true) {
      commit("loadings", { type: "billNotPayed", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/pos/billednotpayed?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_billednotpayed", items);
          commit("loadings", { type: "billednotpayed", isLoading: false });
        });
    },
    loadProductStructure({ commit }, cache = true) {
      commit("loadings", { type: "productStructure", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/product/structure?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_ProductStructure", items);
          commit("loadings", { type: "productStructure", isLoading: false });
        });
    },
    loadProductSoldes({ commit }, cache = true) {
      commit("loadings", { type: "productSoldes", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/soldes/?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_ProductSoldes", items);
          commit("loadings", { type: "productSoldes", isLoading: false });
        });
    },
    loadSoldesRules({ commit }, cache = true) {
      commit("loadings", { type: "soldesRules", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/soldes/rules?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_SoldesRules", items);
          commit("loadings", { type: "soldesRules", isLoading: false });
        });
    },
    loadSupplierStructure({ commit }, cache = true) {
      commit("loadings", { type: "supplierStructure", isLoading: true });
      axios
        .get(
          process.env.VUE_APP_APIURL + "/supplier/structure?cache=" + cache,
          {
            headers: {
              Authorization: `Bearer ${this.state.userToken}`,
            },
            id: this.state.user.id,
          }
        )
        .then((response) => response.data)
        .then((items) => {
          commit("SET_SupplierStructure", items);
          commit("loadings", { type: "supplierStructure", isLoading: false });
        });
    },
    loadSuppliers({ commit }, cache = true) {
      commit("loadings", { type: "suppliers", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/suppliers?cache=" + cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_Suppliers", items);
          commit("loadings", { type: "suppliers", isLoading: false });
        });
    },
    loadCategories({ commit }, cache = true) {
      commit("loadings", { type: "categories", isLoading: true });
      axios
        .get(
          process.env.VUE_APP_APIURL + "/products/categories?cache=" + cache,
          {
            headers: {
              Authorization: `Bearer ${this.state.userToken}`,
            },
            id: this.state.user.id,
          }
        )
        .then((response) => response.data)
        .then((items) => {
          commit("SET_CategoriesList", items);
          commit("loadings", { type: "categories", isLoading: false });
        });
    },
    loadOrders({ commit, dispatch }, obj = { cache: true, next: true }) {
      commit("loadings", { type: "orders", isLoading: true });
      axios
        .get(process.env.VUE_APP_APIURL + "/orders?cache=" + obj.cache, {
          headers: {
            Authorization: `Bearer ${this.state.userToken}`,
          },
          id: this.state.user.id,
        })
        .then((response) => response.data)
        .then((items) => {
          commit("SET_Orders", items);
          commit("loadings", { type: "orders", isLoading: false });
          if (obj.next) dispatch("loadProducts");
        });
    },
    editProductStock({ commit }, data) {
      commit("loadings", { type: "product", isLoading: true });
      let editedProduct = _.filter(this.state.products, { id: data.id })[0];
      editedProduct.stock.quantity = data.value;
      //{{$node["Merge"].json["prestashop"]["product"]["associations"]["stock_availables"]["stock_available"]["$"]["xlink:href"]}}
      var stockId = editedProduct.stock.id;
      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_APIURL + "/product/stock/" + data.id,
            {
              id: this.state.user.id,
              stock: stockId,
              value: data.value,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then(() => {
            commit("CHANGE_Product", { id: data.id, data: editedProduct });
            commit("loadings", { type: "product", isLoading: false });
            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    deleteFeature({ commit }, data) {
      commit("loadings", { type: "features", isLoading: true });
      return new Promise((resolve, reject) => {
        axios
          .delete(process.env.VUE_APP_APIURL + "/feature/" + data.id, {
            headers: {
              Authorization: `Bearer ${this.state.userToken}`,
            },
          })
          .then((response) => response.data)
          .then(() => {
            commit("DELETE_Feature", { id: data.id });
            commit("loadings", { type: "features", isLoading: false });
            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "features", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    deleteProductSoldes({ commit }, data) {
      commit("loadings", { type: "productSoldes", isLoading: true });
      return new Promise((resolve, reject) => {
        axios
          .delete(process.env.VUE_APP_APIURL + "/soldes/" + data.id, {
            headers: {
              Authorization: `Bearer ${this.state.userToken}`,
            },
          })
          .then((response) => response.data)
          .then(() => {
            commit("DELETE_ProductSoldes", { id: data.id });
            commit("loadings", { type: "productSoldes", isLoading: false });
            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "productSoldes", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editProduct({ commit }, data) {
      commit("loadings", { type: "product", isLoading: true });
      let editedProduct = _.filter(this.state.products, { id: data.id })[0];
      switch (data.action) {
        case "active":
          editedProduct.active = "1";
          break;
        case "desactive":
          editedProduct.active = "0";
          break;
        case "price":
          editedProduct.price = data.value;
          break;
        case "wholesale_price":
          editedProduct.wholesale_price = data.value;
          break;
        case "width":
          editedProduct.width = data.value;
          break;
        case "height":
          editedProduct.height = data.value;
          break;
        case "depth":
          editedProduct.depth = data.value;
          break;
        case "editFeatures":
          var index = _.findIndex(
            editedProduct.associations.product_features.product_feature,
            { id: data.idFeature }
          );
          if (index >= 0)
            editedProduct.associations.product_features.product_feature[index][
              "id_feature_value"
            ]["_"] = data.value;
          else {
            editedProduct.associations.product_features.product_feature.unshift(
              editedProduct.associations.product_features.product_feature[0]
            );
            editedProduct.associations.product_features.product_feature[0][
              "id_feature_value"
            ]["_"] = data.value;
            editedProduct.associations.product_features.product_feature[0][
              "id"
            ] = data.idFeature;
          }
          break;
        case "editCategories":
          editedProduct.associations.categories.category = [];
          data.categories.forEach((cat) => {
            var catInfo = _.filter(this.state.categoriesList, {
              id: cat.toString(),
            })[0];
            editedProduct.associations.categories.category.push({
              id: cat,
              value: catInfo.name,
            });
          });
          //verification categorie par défaut
          if (
            _.filter(editedProduct.associations.categories.category, {
              id: editedProduct.id_category_default["_"],
            }).length == 0
          )
            editedProduct.id_category_default[
              "_"
            ] = data.categories[0].toString();
          break;

        case "editContent":
          editedProduct[data.subAction]["language"] = data.content;
          break;
        case "editValue":
          editedProduct[data.subAction] = data.content;
          if (data.subAction == "redirect_type")
            editedProduct["id_product_redirected"] = data.subContent;
          break;
      }

      return new Promise((resolve, reject) => {
        var multi = false;
        if (typeof data.multi != "undefined") multi = data.multi;
        axios
          .put(
            process.env.VUE_APP_APIURL + "/product/" + data.id,
            {
              id: this.state.user.id,
              product: editedProduct,
              multi: multi,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then(() => {
            console.log(editedProduct);
            commit("CHANGE_Product", { id: data.id, data: editedProduct });
            commit("loadings", { type: "product", isLoading: false });
            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editOrder({ commit }, data) {
      commit("loadings", { type: "orders", isLoading: true });
      let editedOrder = _.filter(this.state.orders, { id: data.id })[0];
      switch (data.action) {
        case "refund":
          editedOrder.current_state["_"] = "7";
          editedOrder.state.name.language[0]["_"] = "Remboursé";
          break;
        case "cancel":
          editedOrder.current_state["_"] = "6";
          editedOrder.state.name.language[0]["_"] = "Annulé";
          break;
        case "prepared":
          editedOrder.current_state["_"] = "3";
          editedOrder.state.name.language[0]["_"] = "En cours de préparation";
          break;
        case "acheminement":
          editedOrder.current_state["_"] = "29";
          editedOrder.state.name.language[0]["_"] = "Acheminement en cours";
          break;
        case "delivered":
          editedOrder.current_state["_"] = "5";
          editedOrder.state.name.language[0]["_"] = "Livré";
          break;
        case "ondelivery":
          editedOrder.current_state["_"] = "4";
          editedOrder.state.name.language[0]["_"] = "Expédié";
          break;
        case "validArtisan":
          editedOrder.current_state["_"] = "27";
          editedOrder.state.name.language[0]["_"] = "Validée par l'artisan";
          break;
        case "refuseArtisan":
          editedOrder.current_state["_"] = "28";
          editedOrder.state.name.language[0]["_"] = "Refusée par l'artisan";
          break;
        case "reception":
          editedOrder.current_state["_"] = "30";
          editedOrder.state.name.language[0]["_"] = "Réception chez Tribaliste";
          break;
        case "alertAdmin":
          editedOrder.current_state["_"] = "32";
          editedOrder.state.name.language[0]["_"] = "Need admin";
          break;
        case "collecte":
          editedOrder.current_state["_"] = "31";
          editedOrder.state.name.language[0]["_"] = "En attente de collecte";
          break;
      }

      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_APIURL + "/order/" + data.id,
            {
              id: this.state.user.id,
              order: editedOrder,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then(() => {
            commit("CHANGE_Order", { id: data.id, data: editedOrder });
            commit("loadings", { type: "orders", isLoading: false });
            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "orders", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editAndCreateOrder({ commit }, data) {
      commit("loadings", { type: "orders", isLoading: true });
      let editedOrder = _.filter(this.state.orders, { id: data.id })[0];

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_APIURL + "/order/doli/createOrder",
            {
              id: this.state.user.id,
              order: editedOrder,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((response) => {
            editedOrder.doli = response.doli;
            commit("CHANGE_Order", { id: data.id, data: editedOrder });

            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "orders", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editOrderTrackingStatus({ commit }, data) {
      data.forEach(function(order) {
        let editedOrder = _.filter(this.state.orders, { id: order.id })[0];
        editedOrder.deliveryStatus = {};
        editedOrder.deliveryStatus.info = order.status;
        editedOrder.deliveryStatus.tracking = order.tracking;
        editedOrder.deliveryStatus.carrier = order.carrier;
        editedOrder.deliveryStatus.detail = order.detail;
        console.log(editedOrder);
        commit("CHANGE_Order", { id: data.id, data: editedOrder });
      }, this);
    },
    editAndCreatePO({ commit }, data) {
      commit("loadings", { type: "orders", isLoading: true });
      let editedOrder = _.filter(this.state.orders, { id: data.id })[0];

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_APIURL + "/order/doli/createPO",
            {
              id: this.state.user.id,
              order: editedOrder,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((response) => {
            editedOrder.pos = response.PO;

            commit("CHANGE_Order", { id: data.id, data: editedOrder });

            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "orders", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editPO({ commit }, data) {
      let editedOrder = _.filter(this.state.orders, { id: data.id })[0];
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_APIURL + "/order/doli/editPO",
            {
              id: data.idPO,
              action: data.action,
              reference: editedOrder.reference,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((response) => {
            editedOrder.pos = response.PO;

            commit("CHANGE_Order", { id: data.id, data: editedOrder });

            resolve(data.id);
          })
          .catch((error) => {
            commit("loadings", { type: "orders", isLoading: false });
            reject([error, data.id]);
          });
      });
    },
    editProductsCat({ commit }, data) {
      commit("loadings", { type: "product", isLoading: true });
      var pushProducts = [];
      var thos = this;
      var catInfo = _.filter(this.state.categoriesList, {
        id: data.idCat,
      })[0];
      data.productsToAdd.forEach(function(product) {
        var editedProduct = null;
        editedProduct = _.filter(thos.state.products, {
          id: product.id,
        });
        editedProduct = editedProduct[0];
        editedProduct.associations.categories.category.push({
          id: data.idCat,
          value: catInfo.name,
        });
        pushProducts.push(editedProduct);
      });
      data.productsToRemove.forEach(function(product) {
        var editedProduct = null;
        editedProduct = _.filter(thos.state.products, {
          id: product.id,
        });
        if (typeof editedProduct != "undefined") {
          editedProduct = editedProduct[0];
          _.pullAllBy(
            editedProduct.associations.categories.category,
            [{ id: data.idCat }],
            "id"
          );

          console.log(
            _.filter(editedProduct.associations.categories.category, {
              id: editedProduct.id_category_default["_"],
            })
          );
          if (
            (editedProduct.associations.categories.category,
            {
              id: editedProduct.id_category_default["_"],
            }.length == 0)
          )
            editedProduct.id_category_default[
              "_"
            ] = data.categories[0].toString();
          pushProducts.push(editedProduct);
        } else {
          console.log(product.id);
        }
      });

      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_APIURL + "/products/",
            {
              id: this.state.user.id,
              products: pushProducts,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((items) => {
            // le commit se fait depuis le component via socket !
            commit("loadings", { type: "product", isLoading: false });
            resolve(["productCatEdit", items.socket]);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, "productCatEdit"]);
          });
      });
    },
    editProductsFeatures({ commit }, data) {
      commit("loadings", { type: "product", isLoading: true });
      var pushProducts = [];
      var thos = this;
      var featureInfo = _.filter(this.state.featuresList, {
        id: data.idCat,
      })[0];
      _.forEach(data.products, function(product) {
        var editedProduct = null;
        editedProduct = _.filter(thos.state.products, {
          reference: product,
        });
        editedProduct = editedProduct[0];
        var index = _.findIndex(
          editedProduct.associations.product_features.product_feature,
          { id: data.idFeature.toString() }
        );
        editedProduct.associations.product_features.product_feature[index][
          "id_feature_value"
        ]["_"] = data.value;
        editedProduct.associations.product_features.product_feature[index][
          "value"
        ]["_"] = featureInfo;
        pushProducts.push(editedProduct);
      });

      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_APIURL + "/products/",
            {
              id: this.state.user.id,
              products: pushProducts,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((items) => {
            // le commit se fait depuis le component via socket !
            commit("loadings", { type: "product", isLoading: false });
            resolve(["productCatEdit", items.socket]);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, "productCatEdit"]);
          });
      });
    },
    editProducts({ commit }, data) {
      commit("loadings", { type: "product", isLoading: true });
      var pushProducts = [];
      var thos = this;
      var isStockModification = false;
      data.products.forEach(function(product) {
        var editedProduct = null;
        editedProduct = _.filter(thos.state.products, {
          id: product[data.columns[0]["dataIndex"]],
        });
        if (editedProduct.length < 1)
          editedProduct = _.filter(thos.state.products, {
            reference: product[data.columns[0]["dataIndex"]],
          });
        editedProduct = editedProduct[0];


        for (let i = 1; i < data.columns.length; i++) {
          var action = data.columns[i].selectedMapper;
          if (!action.traductable) {
            if (!action.underscore) {
              switch (action.value) {
                case "categories":
                  editedProduct.associations.categories.category = [];
                  var categories = product[data.columns[i]["dataIndex"]].split(
                    "/"
                  );
                  categories.forEach((cat) => {
                    var catInfo = _.filter(this.state.categoriesList, {
                      id: cat.toString(),
                    })[0];
                    editedProduct.associations.categories.category.push({
                      id: cat,
                      value: catInfo.name,
                    });
                  }, this);
                  break;

                case "stock":
                  editedProduct.stock.edited = true;
                  editedProduct.stock.quantity =
                    product[data.columns[i]["dataIndex"]];
                  isStockModification = true;

                  break;

                case "supplier":
                  editedProduct.id_supplier.edited = true;
                  editedProduct.id_supplier['_'] =
                    product[data.columns[i]["dataIndex"]];

                  break;

                default:
                  editedProduct[action.value] =
                    product[data.columns[i]["dataIndex"]];
                  break;
              }
            } else {
              switch (action.value) {
                default:
                  editedProduct[action.value]["_"] =
                    product[data.columns[i]["dataIndex"]];
                  break;
              }
            }
          } else {
            editedProduct[action.value].language[action.lang]["_"] =
              product[data.columns[i]["dataIndex"]];
          }
        }
        pushProducts.push(editedProduct);
      }, this);
      return new Promise((resolve, reject) => {
        var maxValue = pushProducts.length;
        if (!isStockModification || data.columns.length > 2) {
          axios
            .put(
              process.env.VUE_APP_APIURL + "/products/",
              {
                id: this.state.user.id,
                products: pushProducts,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.state.userToken}`,
                },
              }
            )
            .then((response) => response.data)
            .then((items) => {
              // le commit se fait depuis le component via socket !
              commit("loadings", { type: "product", isLoading: false });
              resolve(["massEdit", items.socket, maxValue]);
            })
            .catch((error) => {
              commit("loadings", { type: "product", isLoading: false });
              reject([error, "massEdit"]);
            });
        } else {
          axios
            .put(
              process.env.VUE_APP_APIURL + "/products/stocks/",
              {
                id: this.state.user.id,
                products: pushProducts,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.state.userToken}`,
                },
              }
            )
            .then((response) => response.data)
            .then((items) => {
              commit("loadings", { type: "product", isLoading: false });
              resolve(["massEdit", items.socket, maxValue]);
            })
            .catch((error) => {
              commit("loadings", { type: "product", isLoading: false });
              reject([error, "massEdit"]);
            });
        }
      });
    },
    addSoldes({ commit }, data) {
      commit("loadings", { type: "productSoldes", isLoading: true });
      var pushSoldes = [];
      var thos = this;
      //var isStockModification = false;
      var editedProduct = null;
      var useSoldes = _.filter(thos.state.soldesRules, {
        id: data.columns[0]["id"],
      })[0];
      data.products.forEach(function(product) {
        var editedSoldes = _.clone(useSoldes);

        editedProduct = _.filter(thos.state.products, {
          id: product[data.columns[0]["dataIndex"]],
        });
        if (editedProduct.length < 1)
          editedProduct = _.filter(thos.state.products, {
            reference: product[data.columns[0]["dataIndex"]],
          });

        if (editedProduct.length > 0) {
          editedProduct = editedProduct[0];
          editedSoldes.id_product = { _: editedProduct["id"] };
          editedSoldes.id_specific_price_rule = editedSoldes.id;
          editedSoldes.id_cart = 0;
          editedSoldes.id_customer = 0;
          editedSoldes.reduction = parseFloat(editedSoldes.reduction) / 100;
          delete editedSoldes.id;
          delete editedSoldes.name;
        } else {
          console.log(product[data.columns[0]["dataIndex"]]);
        }
        pushSoldes.push(editedSoldes);
      }, this);
      return new Promise((resolve, reject) => {
        var maxValue = pushSoldes.length;
        axios
          .put(
            process.env.VUE_APP_APIURL + "/soldes/",
            {
              id: this.state.user.id,
              soldes: pushSoldes,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((items) => {
            commit("loadings", { type: "product", isLoading: false });
            resolve(["massEdit", items.socket, maxValue]);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, "massEdit"]);
          });
      });
    },
    editFeatures({ commit }, data) {
      //uniquement gestion des value
      commit("loadings", { type: "features", isLoading: true });
      var pushProducts = [];
      var thos = this;
      data.features.forEach(function(feature) {
        var editedProduct = null;
        editedProduct = _.filter(thos.state.featuresList, {
          id: feature[data.columns[0]["dataIndex"]],
        });
        editedProduct = editedProduct[0];

        for (let i = 1; i < data.columns.length; i++) {
          var action = data.columns[i].selectedMapper;

          editedProduct.value.language[action.lang]["_"] =
            feature[data.columns[i]["dataIndex"]];
        }
        pushProducts.push(editedProduct);
      }, this);
      // console.log(pushProducts)
      return new Promise((resolve, reject) => {
        var maxValue = pushProducts.length;
        axios
          .put(
            process.env.VUE_APP_APIURL + "/features/",
            {
              id: this.state.user.id,
              features: pushProducts,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.userToken}`,
              },
            }
          )
          .then((response) => response.data)
          .then((items) => {
            // le commit se fait depuis le component via socket !
            commit("loadings", { type: "product", isLoading: false });
            resolve(["massEdit", items.socket, maxValue]);
          })
          .catch((error) => {
            commit("loadings", { type: "product", isLoading: false });
            reject([error, "massEdit"]);
          });
      });
    },
  },
});
