<template>
  <div class="container">
    <div class="row align-items-center profile-header" v-if="!loading">
      <div v-if="user.role == 'admin'">
        <a-row type="flex" :gutter="[16, 16]">
          <a-col :md="12" :xs="24">
            <a-row type="flex" :gutter="[16, 16]" class="stats">
              <a-col :md="12" :xs="12">
                <a-card title="Products">
                  <a-button
                    v-if="loadings.products"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadProducts"
                  />
                  <a-progress
                    type="circle"
                    v-if="loadings.products"
                    :percent="waitPercentProduct"
                  />
                  <a-statistic
                    v-else
                    :value="products.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.orders" title="Orders">
                  <a-button
                    v-if="loadings.orders"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadOrders"
                  />
                  <a-statistic
                    :value="orders.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.features" title="Features">
                  <a-button
                    v-if="loadings.features"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadFeatures"
                  />
                  <a-statistic
                    :value="featuresList.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.categories" title="Categories">
                  <a-button
                    v-if="loadings.categories"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadCategories"
                  />
                  <a-statistic
                    :value="categoriesList.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.suppliers" title="Fournisseurs">
                  <a-button
                    v-if="loadings.suppliers"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadSuppliers"
                  />
                  <a-statistic
                    :value="suppliers.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card
                  :loading="loadings.productSoldes"
                  title="Products on Sales"
                >
                  <a-button
                    v-if="loadings.productSoldes"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadSoldes"
                  />
                  <a-statistic
                    :value="productSoldes.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.soldesRules" title="Sales Rules">
                  <a-button
                    v-if="loadings.soldesRules"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadSoldesRules"
                  />
                  <a-statistic
                    :value="soldesRules.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :md="12" :xs="12">
                <a-card :loading="loadings.pos" title="Commande Artisan">
                  <a-button
                    v-if="loadings.pos"
                    slot="extra"
                    icon="loading"
                  />
                  <a-button
                    v-else
                    slot="extra"
                    icon="reload"
                    @click="reloadPos"
                  />
                  <a-statistic
                    :value="pos.length"
                    :value-style="{ color: '#3f8600' }"
                    style="margin-right: 50px"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div v-else>
        <a-result
          status="403"
          title="403"
          sub-title="Sorry vous n'avez pas encore accès à cette page ! Contactez votre admin"
        >
          <template #extra> </template>
        </a-result>
      </div>
    </div>
    <div class="loading" v-else="">
      <a-row class="content" type="flex" justify="space-around" align="middle">
        <a-col :span="4">
          <a-spin tip="Chargement..." size="large"></a-spin>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      URL: process.env.VUE_APP_APIURL,
      className: "none",
      modalVisible: false,
    };
  },
  computed: {
    ...mapState([
      "user",
      "products",
      "featuresList",
      "categoriesList",
      "suppliers",
      "productSoldes",
      "soldesRules",
      "productsNum",
      "orders",
      "pos",
      "loading",
      "loadings",
      "protected",
    ]),
    waitPercentProduct: function() {
      var percent = (this.products.length / this.protected.products.max) * 100;
      return percent.toFixed(0);
    },
  },
  mounted() {
    if (this.products.length == 0 || this.orders.length == 0)
      this.$store.dispatch("loadUser", this.$auth.user.sub);
  },
  methods: {
    reloadOrders() {
      this.$store.dispatch("loadOrders", { cache : true, next : false});
    },
    reloadProducts() {
      this.$store.dispatch("loadProducts", { cache : true, next : false});
    },
    reloadFeatures() {
      this.$store.dispatch("loadFeatures", false);
    },
    reloadCategories() {
      this.$store.dispatch("loadCategories", false);
    },
    reloadSuppliers() {
      this.$store.dispatch("loadSuppliers", false);
    },
    reloadSoldesRules() {
      this.$store.dispatch("loadSoldesRules", false);
    },
    reloadSoldes() {
      this.$store.dispatch("loadProductSoldes", false);
    },
    reloadPos() {
      this.$store.dispatch("loadPos", false);
    },
  },
};
</script>

<style lang="scss">
.stats .ant-card {
  height: 100%;
}

.upload .ant-upload.ant-upload-drag {
  height: auto;
}
</style>
