import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
// import CV from "../views/CV.vue";
import Products from "../views/Products.vue";
import ProductsMassEdit from "../views/products/mass_edit.vue";
import productsMassUpload from "../views/products/mass_upload.vue";

import ProductsMissingTrad from "../views/products/missing_trad.vue";
import ProductSoldes from "../views/products/soldes.vue";
import ProductsRefnat from "../views/products/refnat.vue";
import ProductsFeatures from "../views/products/features.vue"
import ProductsCat from "../views/products/categories.vue";
import featuresTraduction from "../views/features/featuresTraduction.vue"
import FeaturesDelete from "../views/features/featuresDelete.vue"
import Orders from "../views/Orders.vue";
import Renta from "../views/Renta.vue";
import Pos from "../views/artisans/Pos.vue";
import ArtisansStock from "../views/artisans/stock.vue";
import ArtisansWA from "../views/artisans/whatsapp.vue";
import Login from "../views/admin/Login.vue";
import { authGuard } from "../auth";
import store from "../store/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/admin",
      name: "login",
      component: Login,
      meta: {
        title: "App.profile-check.net | Admin",
      },
    },
    {
      path: "/admin/check",
      name: "check",
      props: true,
      component: () => import("../views/admin/check.vue"),
      meta: {
        title: "App.profile-check.net | Admin",
      },
    },
    {
      path: "/admin/logged",
      name: "logged",
      component: () => import("../views/admin/logged.vue"),
      meta: {
        title: "App.profile-check.net | Admin",
      },
    },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "MO Tribaliste",
      },
    },
    {
      path: "/dashboard",
      name: "profile",
      component: Dashboard,
      beforeEnter: authGuard,
      meta: {
        title: "Dashboard - MO Tribaliste",
      },
    },
    {
      path: "/products",
      name: "Products",
      component: Products,
      beforeEnter: authGuard,
      meta: {
        title: "Produits - MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/massEdit",
      name: "Products_Mass_Edit",
      component: ProductsMassEdit,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Mass Edit- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/massUpload",
      name: "Products_Mass_Upload",
      component: productsMassUpload,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Mass Upload- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/traduction",
      name: "Products_M",
      component: ProductsMissingTrad,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Traduction manquantes- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/soldes",
      name: "Products_Soldes",
      component: ProductSoldes,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Soldes- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/refnat",
      name: "Products_Refnat",
      component: ProductsRefnat,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Refnat- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/categories",
      name: "Products_Cat",
      component: ProductsCat,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Categories- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/products/features",
      name: "Products_Features",
      component: ProductsFeatures,
      beforeEnter: authGuard,
      meta: {
        title: "Produits Features- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/features/delete",
      name: "Products_Features",
      component: FeaturesDelete,
      beforeEnter: authGuard,
      meta: {
        title: "Suppression Features- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/features/traduction",
      name: "Products_Features",
      component: featuresTraduction,
      beforeEnter: authGuard,
      meta: {
        title: "Traduction Features- MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/orders",
      name: "Orders",
      component: Orders,
      beforeEnter: authGuard,
      meta: {
        title: "Commandes - MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/orders/renta",
      name: "Renta",
      component: Renta,
      beforeEnter: authGuard,
      meta: {
        title: "Rentabilité - MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/artisans/pos",
      name: "Pos",
      component: Pos,
      beforeEnter: authGuard,
      meta: {
        title: "POs Artisans - MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/artisans/stocks",
      name: "Stock",
      component: ArtisansStock,
      beforeEnter: authGuard,
      meta: {
        title: "Stocks Artisans - MO Tribaliste",
        authorize: ["admin"],
      },
    },
    {
      path: "/artisans/whatsapp",
      name: "Whatsapp Artisans",
      component: ArtisansWA,
      beforeEnter: authGuard,
      meta: {
        title: "Whatsapp Artisans - MO Tribaliste",
        authorize: ["admin"],
      },
    },
  ],
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = store.getters.user;

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: "/dashboard" });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: "/" });
    }
  }
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
