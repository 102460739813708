<template>
  <div class="editable-cell">
    {{ value * 1 || " " }}
    <span v-if="data.id_currency['_'] == 1">€</span>
    <span v-if="data.id_currency['_'] == 2">$</span>
    <span v-if="data.id_currency['_'] == 4">Dhs</span>

    <div v-if="data.total_discounts_pourcentage != 0">
      <a-tag color="#f50">
        -{{ data.total_discounts * 1 || " " }}
        <span v-if="data.id_currency['_'] == 1">€</span>
        <span v-if="data.id_currency['_'] == 2">$</span>
      </a-tag>
    </div>
    <div v-if="data.products_discounts > 0">
      <a-tag color="#87d068">
        {{ data.products_discounts_pourcentage * 1 }} %
      </a-tag>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "price",
  props: ["text", "data"],
  computed: {
    ...mapState(["language", "loadings"]),
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.editPrice(this.data.id, this.value);
    },
    edit() {
      this.editable = true;
    },
    editPrice(id, value) {
      this.$message.loading({
        content: "Edition prix (" + id + ")...",
        key: id,
      });
      this.$store
        .dispatch("editProduct", { id: id, action: "price", value: value })
        .then((id) => {
          this.$message.success({ content: "Modification réussi", key: id });
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de la modification " + error,
            key: id,
          });
        });
    },
  },
};
</script>
