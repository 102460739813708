<template>
  <div class="container">
    <div class="row align-items-center cv-result" v-if="!loading">
      <!-- <a-row class="line" type="flex" :gutter="16">
        <cvResult />
      </a-row> -->
      <a-row class="line" type="flex" :gutter="16">
        <pos/>
      </a-row>
    </div>
    <div class="loading" v-else="">
      <a-row class="content" type="flex" justify="space-around" align="middle">
        <a-col :span="4">
          <a-spin tip="Chargement..." size="large"></a-spin>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pos from "../../components/artisans/whatsapp.vue";
export default {
  name: "Orders",
  components: { pos },
  data() {
    return {
      waitCV:"...",
    };
  },
  computed: {
    ...mapState(["products", "loading", "loadings"]),
  },
  created() {
    if(!this.products)
      this.$store.dispatch("loadUser", this.$auth.user.sub);
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  text-align: center;

  h1 {
    font-size: 2.4em;
    font-weight: bold;
    margin-bottom: 0;
  }

  h3 {
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1.5;
  }

  h4 {
    font-size: 2em;
  }
}
</style>
