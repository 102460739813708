<template>
  <div class="product_description">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-button icon="close" size="small" @click="notedit" />
      <a-button type="primary" icon="save" size="small" @click="save(record.id)" v-if="edited"/>
      <a-input
        :value="record.wholesale_price * 1"
        @change="handleChange($event)"
      />
    </div>
    <div v-else>
      {{ record.wholesale_price * 1 }}
      <a-button icon="edit" size="small" @click="edit" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "product",
  props: ["record"],
  data() {
    return {
      value: this.text,
      editable: false,
      edited: false,
      initialValues : [],
      editableValues : []
    };
  },
  computed: {
    ...mapState(["user","language","languageDetail", "featuresList"]),
  },
  components: {},
  methods: {
    edit() {
      this.editable = true;
    },
    notedit() {
      this.editable = false;
    },
    save(idProduct) {
      var key = idProduct;
      this.$message.loading({
        content: "Modification wholesale_price (" + idProduct + ")...",
        key: key,
      });
      this.$store
        .dispatch("editProduct", {
          action: "wholesale_price",
          id: idProduct,
          value : this.record.wholesale_price
        })
        .then((id) => {
          this.$message.success({ content: "Edition réussi", key: id });
          this.edited = false;
        })
        .catch(([error, id]) => {
          this.$message.error({
            content: "Echet de l'édition " + error,
            key: id,
          });
        });
    },
    handleChange(e) {
      this.record.wholesale_price = e.target.value
      this.edited = true
    },
  },
};
</script>

<style lang="scss">
.textEdition {
  margin: 10px;
}
</style>>