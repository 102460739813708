<template>
  <div class="PosTable">
    <a-table
      class="orders"
      :columns="columns"
      :data-source="pos_Organize"
      rowKey="id"
      style="{width='100%'; }'"
      :pagination="{
        position: 'both',
        showSizeChanger: true,
        defaultPageSize: 1000,
        pageSizeOptions: ['300', '500', '1000'],
      }"
    >
      <div slot="value" slot-scope="text, data">
        <span v-if="data.id">
        {{text | toMad}}
        </span>
        <span v-else><b>{{text | toMad}}</b></span>
      </div>
      <div slot="valueAct" slot-scope="text, data">
        <span v-if="data.id">
        {{text | toMad}}
        </span>
        <span v-else>
          <b>{{text | toMad}}</b>
          <a-tooltip  
          v-if="text > 0" 
          placement="top"
          title="Créer le virement"
        >
          <a-button type="danger" icon="bank" @click="virement"></a-button>
          </a-tooltip>
        </span>
      </div>
      <div slot="text" slot-scope="text, data">
        <span v-if="data.id">
        {{text}}
        </span>
        <span v-else><b>{{text}}</b></span>
      </div>
      <div slot="action" slot-scope="data" :data="data" v-if="data.id">
        <a-tooltip placement="top" title="Facturer les commandes">
          <a-button
            type=""
            :href="
              'http://factu.host.tribaliste.com/fourn/commande/orderstoinvoice.php?socid=' +
                data.id + '&date_endday='+dateF.day+'&date_endmonth='+dateF.month+'&date_endyear='+dateF.year
            "
            target="_blank"
            icon="file-done"
          />
        </a-tooltip>
      </div>
    </a-table>
  </div>
</template>

<script>
//import _ from "lodash";
import axios from "axios";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    title: "ID",
  },
  {
    dataIndex: "name",
    key: "name",
    title: "REF",
    scopedSlots: { customRender: "text" },
  },
  {
    dataIndex: "attente_reception",
    key: "attente_reception",
    title: "Attente Reception",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "attente_facturation",
    key: "attente_facturation",
    title: "A payer bientôt (<10J)",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "a_facturer",
    key: "a_facturer",
    title: "A Facturer & Payer (>10J)",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "facturenotpayed",
    key: "facturenotpayed",
    title: "A virer",
    scopedSlots: { customRender: "valueAct" },
  },
  {
    dataIndex: "facture",
    key: "facture",
    title: "Déja payé",
    scopedSlots: { customRender: "value" },
  },
  {
    dataIndex: "total",
    key: "total",
    title: "total",
    scopedSlots: { customRender: "value" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "dashboard",
  props: ["type"],
  data() {
    return {
      columns,
      URL: process.env.VUE_APP_APIURL,
      imgUrl: "",
      dateF : {
        day : this.$moment().subtract(10, "days").format("DD"),
        month : this.$moment().subtract(10, "days").format("MM"),
        year : this.$moment().subtract(10, "days").format("YYYY"),
      },
      imgModal: false,
    };
  },
  computed: {
    ...mapState(["pos", "language", "loading", "billednotpayed", "userToken"]),
    pos_Organize() {
      let newPos = [];
      let detail = {};
      this.pos.forEach(function(po) {
        var index = _.findIndex(newPos, { id: po.fourn_id });
        if (index == -1) {          
          detail = {
            id: po.fourn_id,
            name: po.supplier.name,
            attente_reception: 0,
            attente_facturation: 0,
            a_facturer: 0,
            facturenotpayed : 0,
            facture: 0,
            total: 0,
          };
          if (po.billed == "0") {
            if (po.statut == "5") {
              if (
                po.date_commande <
                this.$moment()
                  .subtract(10, "days")
                  .format("X")
              )
                detail.a_facturer = parseFloat(po.total_ttc);
              else detail.attente_facturation = parseFloat(po.total_ttc);
            }
            if (po.statut == "3")
              detail.attente_reception = parseFloat(po.total_ttc);
          } else {
            detail.facture = parseFloat(po.total_ttc);
          }
          newPos.push(detail);
        } else {
          detail = _.clone(newPos[index]);
          if (po.billed == "0") {
            if (po.statut == "5") {
              if (
                po.date_commande <
                this.$moment()
                  .subtract(10, "days")
                  .format("X")
              )
                detail.a_facturer += parseFloat(po.total_ttc);
              else detail.attente_facturation += parseFloat(po.total_ttc);
            }
            if (po.statut == "3")
              detail.attente_reception += parseFloat(po.total_ttc);
          } else {
            detail.facture += parseFloat(po.total_ttc);
          }
          newPos[index] = detail;
        }
      }, this);
      
      console.log(this.billednotpayed);

      this.billednotpayed.forEach(function(bi) {
        var index = _.findIndex(newPos, { id: bi.fk_soc });
        if (index > -1) { 
          detail = _.clone(newPos[index]);
          detail.facturenotpayed += parseFloat(bi.total_ttc)
          detail.facture -= parseFloat(bi.total_ttc)
        }
        newPos[index] = detail;
      }, this);

      var total = {
        name: "Total",
        attente_reception: 0,
        attente_facturation: 0,
        a_facturer : 0,
        facturenotpayed : 0,
        facture: 0,
        total: 0,
      };


      newPos.forEach(function(po, key) {
        total.attente_reception += po.attente_reception;
        total.attente_facturation += po.attente_facturation;
        total.a_facturer += po.a_facturer;
        total.facturenotpayed += po.facturenotpayed;
        total.facture += po.facture;
        newPos[key]["total"] =
          po.attente_reception + po.attente_facturation + po.facture + po.facturenotpayed + po.a_facturer;
        total.total += newPos[key]["total"];
      });
      newPos.push(total);
      return newPos;
    },
  },
  filters: {
    toMad (value) {
      return `${value.toLocaleString()} dhs`
    }
  },
  methods: {
    showModal(img) {
      this.imgModal = true;
      this.imgUrl = img;
    },
    virement() {
      axios({
        url: process.env.VUE_APP_APIURL + "/suppliers/virement",
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
        responseType: "blob", // important
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "virement_"+this.$moment().format("DD_MM_YYYY")+".xlsx");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.PosTable {
  width: 90%;
}
</style>
