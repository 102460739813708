<template>
  <div>
    <p>Redirection...</p>
  </div>
</template>

<script>

export default {
  name: 'home',
  data() {
    return {
      callLogin: false
    };
  },
  methods: {
    login() { 
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    }
  },mounted() { 

    this.$auth.$on('loged', this.login())
  }
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
